import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import "./index.CreateReport.css";
import icon from "../../assets/icons/iconExcel.svg";
import Warning from "../../assets/icons/warning-icon.svg";
import Download from "../../assets/icons/downloadReport-icon.svg";
import { useState } from "react";
import {
  collectionReportExist,
  collectionReport,
} from "../../api/recollectionReportApi";

const CreateReport = () => {
  const [generatingReport, setGeneratingReport] = useState(false);
  const [reportGenerated, setReportGenerated] = useState(false);
  const [reportUrl, setReportUrl] = useState(null);

  useEffect(() => {
    collectionReportExist()
      .then((res) => {
        if (res.data.status_Code === 200) {
          if (res.data.status_Message === "report exist") {
            setReportUrl(res.data.url_daily_report);
            setReportGenerated(true);
          } else {
            setReportGenerated(false);
          }
        } else {
          // alert(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleGenerateReport = () => {
    setGeneratingReport(true);
    /*Logica para general el reporte  */
    collectionReport()
      .then((res) => {
        if (
          res.data.status_Code === 201 &&
          res.data.status_Message === "Reporte diario generado exitosamente"
        ) {
          setGeneratingReport(false);
          setReportGenerated(true);
          setReportUrl(res.data.url_daily_report);
          localStorage.setItem("reportGenerated", "true");
        } else {
          // alert("El archivo no contienes datos por el momento");
          setGeneratingReport(false);
          setReportGenerated(false);
        }
      })
      .catch((err) => {
        throw new Error("Error al descargar el reporte")
      });

    setTimeout(() => {}, 2000);
  };

  const handleDownloadReport = () => {
    window.open(reportUrl, "_blank");
  };

  const currentDate = new Date();

  const daysOfWeek = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];
  const monthsOfYear = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const dayOfWeek = daysOfWeek[currentDate.getDay() - 1];
  const month = monthsOfYear[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  const formattedDate = `${dayOfWeek} ${day} de ${month} del ${year} `;

  return (
    <Layout>
      <div className="container">
        <h1>Genera tu reporte de recolección</h1>
        <h3>Da clic en el siguiente botón para generar tu reporte de Excel</h3>
        <div className="date">
          <p className="dateTitle">Fecha de reporte</p>
          <p className="currentDate">{formattedDate}</p>
        </div>
        {reportGenerated ? (
          <button onClick={handleDownloadReport} className="downloadButton">
            Descargar <img src={Download} alt="" />
          </button>
        ) : generatingReport ? (
          <button disabled={true}>
            Generando <div className="spinner"></div>
          </button>
        ) : (
          <button onClick={handleGenerateReport} className='btnGenerar'>
            Generar Reporte <img src={icon} alt="" />
          </button>
        )}
        {generatingReport && (
          <div className="warningContainer">
            <div className="warningContent">
              <img src={Warning} alt="" className="warningIcon" />
              <p className="warningMessage">
                Por favor, no cierres el navegador ni interrumpas el proceso.
                Esto puede demorar varios minutos.
              </p>
            </div>
          </div>
        )}
        {reportGenerated && (
          <p className="successMessage">
            El reporte del día de hoy ya ha sido generado. <br />
            Si deseas, puedes descargarlo nuevamente.
          </p>
        )}
      </div>
    </Layout>
  );
};

export default CreateReport;
