import React from "react";
import Layout from "../../components/Layout";
import ui from "./index.module.css";
import SmallCard from "../../components/Stats/SmallCard";
import BgImageCard1 from "../../assets/images/smallCard-image-1.svg";
import BgImageCard2 from "../../assets/images/smallCard-image-2.svg";
import BgImageCard3 from "../../assets/images/smallCard-image-3.svg";
import GraphicExample from "../../assets/images/graphic-example.png";
import useWindowSize from "../../hooks/useWindowSize";
import MediumCard from "../../components/Stats/MediumCard";

const Home = () => {

  const { width } = useWindowSize();

  return (
    <Layout>
      <div id={ui.page}>
        <h1 className={ui.pageTitle}>Información general</h1>
        <section>
          <section className={ui.sectionSmallCards}>
            <SmallCard title={"Nuevos clientes"} value={"2500"} name={"Clientes"} bg={BgImageCard1} />
            <SmallCard title={"Guías del mes"} value={"400"} name={"Creadas"} bg={BgImageCard2} />
            <SmallCard title={"Guías de recolectar"} value={"50"} name={"Guías"} bg={BgImageCard3} />
          </section>
          {
            width > 480
              ? (
                <section className={ui.sectionMediumCards}>
                  <MediumCard title={"Ingresos Mensuales"} value={8584} />
                  <MediumCard title={"Ingresos del día"} value={500} />
                </section>
              )
              : null
          }
          <section className={ui.sectionGraphic}>
            <div className={ui.graphicWrapper}>
              <img src={GraphicExample} alt="graph" />
            </div>
          </section>
        </section>
      </div>
    </Layout>
  );
};

export default Home;
