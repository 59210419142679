import axios from "axios";
const { REACT_APP_API_URL } = process.env;

// Configuración global de Axios
const apiClient = axios.create({
  baseURL: `${REACT_APP_API_URL}`,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const get = async (url, authToken = null, config = {}) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      ...(authToken && { auth_token: authToken }),
    };

    const response = await apiClient.get(url, { ...config, headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const post = async (url, data, authToken = null, config = {}) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      ...(authToken && { auth_token: authToken }),
    };
 
    const response = await apiClient.post(url, data, { ...config, headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default apiClient;
