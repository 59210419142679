import React from "react";
import "./loaderTable.css";

const LoaderTable = () => {
  return (
    <div className="containerLoader">
        <div class="dot-spinner">
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        </div>
    </div>
  );
};

export default LoaderTable;
