import ui from "./index.module.css";
import { useContext } from "react";
import { NotificationContext } from "../../context/NotificationContext";
import { useEffect } from "react";
import successAnimation from "../../assets/lottieAnimations/successAnimation.json";
import failureAnimation from "../../assets/lottieAnimations/failureAnimation.json";
import Lottie from "react-lottie";

//Lottie configuration animations
const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const successOptions = {
  ...defaultOptions,
  animationData: successAnimation,
};

const failureOptions = {
  ...defaultOptions,
  animationData: failureAnimation,
};

const ModalResponse = ({ notificationType, refetchData }) => {
  const { handleCloseNotification } = useContext(NotificationContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleCloseNotification();
      refetchData();
    }, 3000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCloseNotification]);

  return (
    <div className={ui.modalResponse}>
      <span
        className={ui.modalClose}
        onClick={() => handleCloseNotification()}
      ></span>
      <div className={ui.modalContainer}>
        {notificationType ? <Success /> : <Failure />}
      </div>
    </div>
  );
};

const Success = () => {
  return (
    <>
      <h1 className={ui.containerHeaders}>Los datos se guardaron con éxito</h1>
      <div className={ui.containerImage}>
        <Lottie options={successOptions} height={120} width={120} />
      </div>
    </>
  );
};

const Failure = () => {
  return (
    <>
      <h1 className={ui.containerHeaders}>¡Oh no! Algo salió mal</h1>
      <div className={ui.containerImage}>
        <Lottie options={failureOptions} height={120} width={120} />
      </div>
    </>
  );
};

export default ModalResponse;
