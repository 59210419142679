import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_API_URL } = process.env;

const accessToken = getCookie("accessToken");

export const getClientsInformation = (payload) => {
  const request = {
    method: "GET",
    url: `${REACT_APP_API_URL}admin/get-business-list`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_token: accessToken,
    },
  };
  return axios(request);
};

export const addCreditsToClient = (userId, credits) => {
  const payload = {
    destiny_user: parseInt(userId),
    payment_method: 1,
    credits_amount: parseInt(credits),
  };

  const request = {
    method: "POST",
    url: `${REACT_APP_API_URL}business/add-credits`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_token: accessToken,
    },
  };
  return axios(request);
};

export const removeCreditsToClient = (userId, credits) => {
  const payload = {
    destiny_user: parseInt(userId),
    credits_amount: parseInt(credits),
  };

  const request = {
    method: "POST",
    url: `${REACT_APP_API_URL}admin/reduce-credits`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_token: accessToken,
    },
  };
  return axios(request);
};
