
import ui from "../index.module.css";

const SmallCard = ({ title, value, name, bg }) => {
  return (
    <article className={ui.card}>
      <div className={ui.smallCard}>
        <h1 className={ui.cardTitle}>{title}</h1>
        <span className={ui.cardValue}>{value}</span>
        <p className={ui.cardName}>{name}</p>
      </div>
      <img src={bg} className={ui.cardBgImage} alt="graphic" />
    </article>
  )
}

export default SmallCard;