import React from "react";
import styled, { css } from "styled-components";
import { slideUpAnimation } from "../../animations/cssAnimation";

const DropShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.44);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  border-radius: 0.625rem;
  animation: ${slideUpAnimation} 0.3s ease;
  ${(props) =>
    props.dropShadow &&
    css`
      box-shadow: 0 0.1875rem 0.625rem rgba(0, 0, 0, 0.15);
    `}
  @media (min-width: 0px) and (max-width: 767px) {
    width: 500px;
    height: 400px;
  }
`;

const BodyModal = (props) => {
  const { dropShadow, bodyHTML, open } = props;
  if (!open) {
    return null;
  }

  return (
    <DropShadow>
      <ModalContainer dropShadow={dropShadow}>{bodyHTML}</ModalContainer>
    </DropShadow>
  );
};

export default BodyModal;
