import React from "react";
import Layout from "../../components/Layout";
import ui from "./index.module.css";
import SearchIcon from "../../assets/icons/search-icon.svg";
import BasicTable from "./BasicTable";
import useFetch from "./hooks/useFetch";
import Modal from "../../components/ModalWrapper";
import useAction from "./hooks/useAction";
import useWindowSize from "../../hooks/useWindowSize";
import Pagination from "./BasicTable/Pagination";
import useComponent from "./hooks/useComponent";
import ModalResponse from "../../components/ModalResponse";
import { useContext } from "react";
import { NotificationContext } from "../../context/NotificationContext";

const Balance = () => {

  const {
    balances,
    headers,
    limit,
    offset,
    setOffset,
    currentPage,
    setCurrentPage,
    count,
    refetchData
  } = useFetch();

  const {
    selectToggleModal,
    getModalStatus
  } = useAction();

  const {
    width: w,
    // height: h
  } = useWindowSize();

  const {
    thereIsComponent
  } = useComponent({ selectToggleModal });

  const {
    notification,
    notificationType
  } = useContext(NotificationContext);
  
  const lastIndex = currentPage * offset
  const firstIndex = lastIndex - offset

  return (
    <Layout>
      <div id={ui.page}>
        <h1>Saldo de usuarios</h1>
        <p>Resumén de usuarios que tienen saldos, modifica o asigna créditos..</p>
        <section className={ui.body}>
          <div className={ui.container}>
            <div className={ui.containerFilters}>
              <div className={ui.filterSearchBar}>
                <div className={ui.searchBar}>
                  <input type="text" placeholder="Buscar usuario" />
                  <div className={ui.searchIcon} style={{ backgroundImage: `url(${SearchIcon})` }}></div>
                </div>
                {
                  w <= 834 && w > 480
                    ? (
                      <select name="filter" title="Filtro" id={ui.filterSelect}>
                        <option value={0}>Selecciona un filtro</option>
                      </select>
                    ) : null
                }
              </div>
            </div>
            <div className={ui.containerTable}>
              <BasicTable
                information={balances}
                headers={headers}
                thereIsComponent={thereIsComponent}
                lastIndex={lastIndex}
                firstIndex={firstIndex}
              />
              <Pagination
                count={count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limit={limit}
                offset={offset}
                setOffset={setOffset}
              />
            </div>
          </div>
        </section>
      </div>
      <Modal
        getModalStatus={getModalStatus}
        selectToggleModal={selectToggleModal}
      />
      {
        notification
          ? <ModalResponse notificationType={notificationType} refetchData={refetchData} />
          : null
      }
    </Layout>
  );
};

export default Balance;