// import { useState } from "react";
import ModalContentAdmin from "../views/ModalAdmin";
import ModalChangeState from "../views/ModalChangeState";
import ModalContent from "../views/ModalContent";
import ModalContentRepartidor from "../views/ModalRepartidor";
import ModalSucursal from "../views/ModalSucursal";
import ModalViewUser from "../views/ModalViewUser";
import ModalChangeStateGuides from "../../StatusPackage/views/ModalChangeStateGuides";
import ModalGetBalanceHistory from "../../Balance/views/ModalGetBalanceHistory";


const useModal = ({ selectToggleModal, getModalStatus, data }) => {

  const { t: option } = getModalStatus();

  const viewComponent = (type, index) => {

    const titles_list = [
      { main: "Elige el tipo de usuario", strong: "" },
      { main: "Creación de usuario", strong: "Admin" },
      { main: "Creación de usuario", strong: "Repartidor" },
      { main: "Usuario", strong: "Sucursal" },
      { main: "Usuario", strong: "Sucursal" },
      {
        isTrue: "Estas a punto de desactivar al usuario:",
        isFalse: "Estas a punto de activar al usuario:",
        strong: ""
      },
      { main: "Entregar", strong: "Paquete" },
      { main: "Usuario", strong: "Este" },
    ];

    const styled_h1 = {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal"
    }

    const styled_span = {
      color: "#C92F2F",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal"
    }

    if (type === 0) {
      return (
        <>
          <h1 style={styled_h1}>{titles_list[option].main} {" "} {titles_list[option].strong}</h1>
          <ModalContent
            selectToggleModal={selectToggleModal}
            select={option}
          />
        </>
      )
    }

    if (type === 1) {
      return (
        <>
          <h1 style={styled_h1}>{titles_list[option].main} {" "} <span style={styled_span}>{titles_list[option].strong}</span></h1>
          <ModalContentAdmin
            selectToggleModal={selectToggleModal}
            type={type}
          />
        </>
      )
    }

    if (type === 2) {
      return (
        <>
          <h1 style={styled_h1}>
            {titles_list[option].main} {" "} <span style={styled_span}>{titles_list[option].strong}</span>
          </h1>
          <ModalContentRepartidor
            selectToggleModal={selectToggleModal}
            type={type}
          />
        </>
      )
    }

    if (type === 3) {
      return (
        <>
          <h1 style={styled_h1}>
            {titles_list[option].main} {" "} <span style={styled_span}>{titles_list[option].strong}</span>
          </h1>
          <ModalSucursal
            selectToggleModal={selectToggleModal}
            type={type}
          />
        </>
      )
    }

    if (type === 4) {
      return (
        <>
          <h1 style={styled_h1}>
            {titles_list[option].main} {" "} <span style={styled_span}>{titles_list[option].strong}</span>
          </h1>
          <ModalViewUser
            selectToggleModal={selectToggleModal}
            type={type}
            reference={index}
          />
        </>
      )
    }

    if (type === 5) {
      return (
        <>
          <h1 style={{ ...styled_h1, textAlign: "center" }}>
            {titles_list[option].main} {" "} <span style={styled_span}>{titles_list[option].strong}</span>
          </h1>
          <ModalChangeState
            selectToggleModal={selectToggleModal}
            reference={index}
            title={titles_list[option]}
            styled={{ main: styled_h1, strong: styled_span }}
          />
        </>
      )
    }
    if (type === 6) {
      return (
        <>
          <h1 style={{ ...styled_h1, textAlign: "center" }}>
            {titles_list[option].main} {" "} <span style={styled_span}>{titles_list[option].strong}</span>
          </h1>
          <ModalChangeStateGuides
            selectToggleModal={selectToggleModal}
            reference={index}
            info={data}
            title={titles_list[option]}
            styled={{ main: styled_h1, strong: styled_span }}
          /> 
        </>
      )
    }
    if (type === 7) {
      return (
        <>
          <ModalGetBalanceHistory
            selectToggleModal={selectToggleModal}
            reference={index}
            info={data}
            title={titles_list[option]}
            styled={{ main: styled_h1, strong: styled_span }}
          /> 
        </>
      )
    }
  }

  return {
    viewComponent
  }
}

export default useModal;