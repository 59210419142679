import React from "react";
import { styled } from "styled-components";
import { COLORS } from "../../../../constants/theme";
import Button from "../../../../components/sharedComponents/Button";

const ConfirmSubmitionModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 22.5625rem;
  height: 13rem;
  background: ${COLORS.WHITE};
  border-radius: 0.625rem;

  @media (min-width: 260px) and (max-width: 767px) {
    width: 80vw;
    height: 80vw;
  }
  
`;

const SubmitionModalTitle = styled.h1`
  color: #000;
  font-family: MontserratMedium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding: 2rem;
`;

const SubmitionModalBottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-item: center;
  padding-bottom: 2rem;
`;

const ModalContent = (props) => {
  const { confirm, cancel, disabledButton, isAdding } = props;
  return (
    <ConfirmSubmitionModalContainer>
      <SubmitionModalTitle>
        ¿Estas seguro de {isAdding ? "agregar" : "remover"} créditos a este
        usuario?
      </SubmitionModalTitle>

      <SubmitionModalBottomContainer>
        <Button
          text="Cancelar"
          variant="outlined"
          size="sm"
          handleClick={cancel}
        />
        <Button
          text="Confirmar"
          variant="primary"
          size="sm"
          handleClick={confirm}
          ml={2}
          disabled={disabledButton}
        />
      </SubmitionModalBottomContainer>
    </ConfirmSubmitionModalContainer>
  );
};

export default ModalContent;