import React from "react";
import Drawer from "./Drawer";
import ui from "./index.module.css";

const Layout = ({ children }) => {

  return (
    <>
      <Drawer />
      <main id={ui.wrapper}>
        <div id={ui.wrapContainer}>
          {children}
        </div>
      </main>
    </>
  );
};

export default Layout;