const mapToArray = (Array) => {
    return Array?.map((object) => {
      const adress = [object.street_name, object.colony, object.ext_number].join(" ");
      return [
        object.tracking_code,
        object.creation_date,
        adress,
        object.postal_code,
        object.actual_status,
        object.id_guide,
        object.id_batch,
      ]
    });
  }
  
  const mapper = (d) => {
    const headers = [
      "ID GUIA",
      "FECHA CREACION",
      "DIRECCION",
      "CP",
      "ESTADO",
      "ACCIONES"
    ];
  
    const orders = mapToArray(d);
  
    return {
      headers,
      orders
    }
  }
  
  export default mapper;