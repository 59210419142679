
import axios from "axios";
import { getCookie } from '../utils/sessionCookie';
const { REACT_APP_API_URL } = process.env;



export const getDealers = () => {

    const token = getCookie('accessToken')

    const request = {
        host: REACT_APP_API_URL,
        method: "GET",
        url: `${REACT_APP_API_URL}/admin/get-dealers`,
        headers: {
        "content-type": "application/json",
        auth_Token: token,
        },
    };

return axios(request);
};