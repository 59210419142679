import { useState, useEffect } from "react";
import mapper from "./mapper";
import axios from "axios";
import { getCookie } from "../../../utils/sessionCookie";

const useFetch = () => {

  const [offset, setOffset] = useState(10);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [getData, setGetData] = useState([]);
  const [refetch, setRefetch] = useState(null);
  const [loader, setLoader] = useState(false)

  const refetchData = () => setRefetch(!refetch);

  useEffect(() => {

    async function httpRequest() {
      setLoader(true)
      if (refetch === false) return;

      const url = process.env.REACT_APP_API_URL + "admin/get-balance-users";
      const token = getCookie("accessToken");

      const headers = {
        headers: {
          "auth_token": token,
          "Content-Type": "application/json;charset=UTF-8"
        }
      }

      await axios
        .get(url, headers)
        .then(response => {
          // console.log("== FETCHING DATA ==")
          // console.log(response);
          const { data } = response;
          const total_clients = data?.clients === undefined ? 0 : data?.clients.length;
          if (total_clients === 0) return [];
          setCount(total_clients);
          setGetData(data.clients);
          setRefetch(false);
          setTimeout(() => {
            setLoader(false)
          }, 500);
        })
        .catch(error => {
          // console.log({ error })
          return []
        });
    }

    httpRequest();
  }, [refetch]);


  const { balances, headers } = mapper(getData);

  return {
    balances,
    headers,
    offset,
    setOffset,
    count,
    currentPage,
    setCurrentPage,
    refetchData,
    loader
  }
}

export default useFetch;