const mapToArray = (Array) => {
    return Array?.map((object) => {
      return [
        object.id_batch,
        object.user,
        object.street_name,
        object.colony,
        object.postal_code,
        object.int_number,
        object.ext_number,
        object.state,
        object.city,
        object.total_guides,
        object.total_weight,
      ]
    });
    
  }

  
  const mapper = (d) => {
    const headers = [
      "",
      "NO. LOTE",
      "NOMBRE",
      "CALLE",
      "COLONIA",
      "CP",
      "NUMERO INT",
      "NUMERO EXT",
      "ESTADO",
      "MUNICIPIO",
      "GUIAS",
      "PESO"
    ];
  
    const batchs = mapToArray(d);
  
    return {
      headers,
      batchs
    }
  }
  
  export default mapper;