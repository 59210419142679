import useModal from "../../pages/Users/hooks/useModal";
import ui from "./index.module.css";

const Modal = ({ selectToggleModal, getModalStatus }) => {

  const { t: type, f: flag, i: indexU } = getModalStatus();
  const { viewComponent } = useModal({ selectToggleModal, getModalStatus });

  const styledClass = () => {
    if (type === 0) return ui.modalContent;
    if (type === 1 || type === 2) return ui.modalAnchor;
    if (type >= 3 && type <=6) return ui.modalAnchor2;
    if (type === 7) return ui.modalAnchor3;
  }

  return flag ? (
    <div className={ui.modal}>
      <span className={ui.modalCloseBg} onClick={() => selectToggleModal(0, false)}></span>
      <div className={styledClass()}>
        <span className={ui.modalClose} onClick={() => selectToggleModal(0, false)}>&times;</span>
        <div className={ui.contentBody}>
          {viewComponent(type, indexU)}
        </div>
      </div>
    </div>
  ) : null;
}

export default Modal;