import ui from "./index.module.css";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { getCookie } from "../../../../utils/sessionCookie";
import { NotificationContext } from "../../../../context/NotificationContext";
import { getBalanceHistory } from "../../../../api/getBalanceHistory";
import iconClose from "../../../../assets/icons/CloseIcon.svg"
import { addReduceBalance } from "../../../../api/addReduceBalance";
// import useWindowSize from "../../../../hooks/useWindowSize";

const ModalGetBalanceHistory = ({
  selectToggleModal,
  reference,
}) => {

  const [balance, setBalance] = useState({
    nameUser: '',
    idBalanceUser: '',
  })
  const [dataTable, setdataTable] = useState([]);
  const [actualBalance, setActualBalance] = useState(0)

  const token = getCookie("accessToken");
  // const { width } = useWindowSize();

  useEffect(() => {
    // setBalance({
    //   nameUser: reference[0],
    //   idBalanceUser: reference[1]
    // })
    
    setBalance(prevState => ({
      ...prevState,
      nameUser: reference[0],
      idBalanceUser: reference[1]
    }));

    const fetchData = async () =>{
      try {
        const payload = {
          id_user : balance.idBalanceUser,      
        }
        const response = await getBalanceHistory(payload,token);
        if (response.data.status_Code === 200) {
          // Se obtiene el valor actual
          const balanceValue = response.data.actual_balance  
          ? parseFloat(response.data.actual_balance) : null;
          setdataTable(response.data.balance_history)
          setActualBalance(balanceValue)
          console.log('balanceFetch',balanceValue)
        }
      } catch (error) {
        console.error('Error fetching balance history', error)
      }
    }
    if (reference.length > 0) {
      fetchData();
    }

    // fetchData();
 
  }, [reference, balance.idBalanceUser, token])
  
  const fetchDataAction = async () =>{
    try {
      const payload = {
        id_user : balance.idBalanceUser,      
      }
      const response = await getBalanceHistory(payload,token);
      if (response.data.status_Code === 200) {
        // Se obtiene el valor actual
        const balanceValue = response.data.actual_balance 
        ? parseFloat(response.data.actual_balance) : null;
        setdataTable(response.data.balance_history)
        setActualBalance(balanceValue)
        // console.log(balanceValue)
      }
    } catch (error) {
      console.error('Error fetching balance history', error)
    }
  }
  // Notificacion
  const { handleShowNotification } = useContext(NotificationContext);

  const [inputValue, setInputValue] = useState(); 
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const [typeCaseAmount, setTypeCaseAmount] = useState("")
  // Modal Añadir
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const openSecondModal = () => {
    setIsSecondModalOpen(true);
    setTypeCaseAmount('Aumento')
  };
  const closeSecondModal = () => {
    setIsSecondModalOpen(false);
    setTypeCaseAmount('')
  };
  // Modal reducir
  const [isTthirdModalOpen, setIsThirdModalOpen] = useState(false);
  const openThirdModal = () => {
    setIsThirdModalOpen(true);
    setTypeCaseAmount('Reducción')
  };
  const closeThirdModal = () => {
    setIsThirdModalOpen(false);
    setTypeCaseAmount('')
  };
  // Funcion de cambiar saldo
  // const handleModificateBalance = () => {
  //   const payload = {
  //       destiny_user : balance.idBalanceUser,
  //       type_case : typeCaseAmount,
  //       amount : parseInt(inputValue) 
  //   }
  //   addReduceBalance(payload, token)
  //   .then(res => {
  //     if (res.data.status_Code === 200 && res.data.status_Message === 'successful operation') {
  //       setActualBalance(res.data.actual_balance)
  //       console.log(actualBalance)
  //       setIsSecondModalOpen(false);
  //       setIsThirdModalOpen(false);
  //       fetchDataAction()
  //       handleShowNotification(addReduceBalance);

  //     }
  //     else{
  //       setIsSecondModalOpen(false);
  //       setIsThirdModalOpen(false);
  //       handleShowNotification();
  //     }
  //   })
  //   .catch((error) => {
  //     console.error('Error en la llamada a la API:', error);
  //   });

  // }

  const handleModificateBalance = async () => {
    try {
      const payload = {
        destiny_user: balance.idBalanceUser,
        type_case: typeCaseAmount,
        amount: parseInt(inputValue),
      };
  
      const res = await addReduceBalance(payload, token);
  
      if (res.data.status_Code === 200 && res.data.status_Message === 'successful operation') {
        setActualBalance(res.data.actual_balance)
        const newActualBalance = res.data.actual_balance;
        console.log('newActualBalance:', newActualBalance);
        setIsSecondModalOpen(false);
        setIsThirdModalOpen(false);
        fetchDataAction();
        handleShowNotification(addReduceBalance);
        setActualBalance(newActualBalance);
        console.log( actualBalance)

      } else {
        setIsSecondModalOpen(false);
        setIsThirdModalOpen(false);
        handleShowNotification();
      }
    } catch (error) {
      console.error('Error en la llamada a la API:', error);
    }
  };

  return (
    <>
      <div className={ui.modalUserContent}>
        <p className={ui.title}> Usuario <span  className={ui.contentName} > {balance.nameUser} </span> </p>
        <p className={ui.textSaldo}>saldo actual</p>
        <p className={ui.saldo}>${actualBalance !== null ? actualBalance : '0'}</p>
        <p className={ui.textButtons}>Modificar Saldo</p>  
        <div className={ui.containerButtonModal}>
          <button className={ui.btnAdd} onClick={openSecondModal}>Agregar</button>
          <button className={ui.btnRedu} onClick={openThirdModal}>Remover</button>
        </div>

        {/* {width <= 480 ? (
          dataTable.map((item, index)=>(
            <div key={index} className={ui.containerTableMin}>
              <div className={ui.topContainerTableMin}>
                <div className={ui.containerTextTableMin}>
                  <p className={ui.titleTextTableMin}>Tipo</p>
                  <p className={ui.textTableMin}>{item[1].amu}</p>
                </div>
                <div className={ui.containerTextTableMin}>
                  <p className={ui.titleTextTableMin}>Cantidad</p>
                  <p className={ui.textTableMin}>{item.amount}</p>
                </div>
                <div className={ui.containerTextTableMin}>
                  <p className={ui.titleTextTableMin}>Descripcion</p>
                  <p className={ui.textTableMin}>{item.description_case}</p>
                </div>
              </div>
              <div className={ui.bottomContainerTableMin}>
                <div className={ui.containerTextTableMin}>
                  <p className={ui.titleTextTableMin}>Saldo Anterior</p>
                  <p className={ui.textTableMin}>{item.before_balance}</p>
                </div>
                <div className={ui.containerTextTableMin}>
                  <p className={ui.titleTextTableMin}>Saldo Actual</p>
                  <p className={ui.textTableMin}>{item.after_balance}</p>
                </div>
                <div className={ui.containerTextTableMin}>
                  <p className={ui.titleTextTableMin}>Fecha</p>
                  <p className={ui.textTableMin}>{item.register_date}</p>
                </div>
              </div>
            </div>
          ))

        ):(
          <div className={ui.tableContainer}>
            <table>
              <thead>
                <tr className={ui.thTable}>
                  <th className={ui.renglon}>Tipo</th>
                  <th className={ui.renglon}>Cantidad</th>
                  <th className={ui.renglon}>Descripción</th>
                  <th className={ui.renglon}>Saldo anterior</th>
                  <th className={ui.renglon}>Saldo actual</th>
                  <th className={ui.renglon}>Fecha de registro</th>
                </tr>
              </thead>
              <tbody>
                {dataTable.map((item, index)=>(
                  <tr key={index} className={ui.tdTable}>
                    <td className={ui.renglon}>{item.type_case}</td>
                    <td className={ui.renglon}>{item.amount}</td>
                    <td className={ui.renglon}>{item.description_case}</td>
                    <td className={ui.renglon}>{item.before_balance}</td>
                    <td className={ui.renglon}>{item.after_balance}</td>
                    <td className={ui.renglon}>{item.register_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )} */}

          <div className={ui.tableContainer}>
            <table>
              <thead>
                <tr className={ui.thTable}>
                  <th className={ui.renglonth}>Tipo</th>
                  <th className={ui.renglonth}>Cantidad</th>
                  <th className={ui.renglonth}>Descripción</th>
                  <th className={ui.renglonth}>Saldo anterior</th>
                  <th className={ui.renglonth}>Saldo actual</th>
                  <th className={ui.renglonth}>Fecha de registro</th>
                </tr>
              </thead>
              <tbody>
                {dataTable.map((item, index)=>(
                  <tr key={index} className={ui.tdTable}>
                    <td className={ui.renglon}>{item.type_case}</td>
                    <td className={ui.renglon}>{item.amount}</td>
                    <td className={ui.renglon}>{item.description_case}</td>
                    <td className={ui.renglon}>{item.before_balance}</td>
                    <td className={ui.renglon}>{item.after_balance}</td>
                    <td className={ui.renglon}>{item.register_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        
        {/* Modal Agregar */}
        {isSecondModalOpen && (
          <div className={ui.modalSegundo}>
            <img src={iconClose} className={ui.imgClose} onClick={closeSecondModal} alt="" />
            <p className={ui.textSaldo}>Saldo Actual</p>
            <p className={ui.saldoModal}>${actualBalance}</p>
            <input 
            className={ui.inputModal}
            value={inputValue}
            onChange={handleInputChange}
            type="text" 
            placeholder="Monto" />
            <button className={ui.btnModalSaldo} onClick={handleModificateBalance}>Agregar</button>
          </div>
        )}
        {/* Modal Remover */}
        {isTthirdModalOpen && (
          <div className={ui.modalSegundo}>
            <img src={iconClose} className={ui.imgClose} onClick={closeThirdModal} alt="" />
            <p className={ui.textSaldo}>Saldo Actual</p>
            <p className={ui.saldoModal}>${actualBalance}</p>
            <input 
            className={ui.inputModal}
            value={inputValue}
            onChange={handleInputChange}
            type="text"
            placeholder="Monto" />
            <button className={ui.btnModalSaldo} onClick={handleModificateBalance}>Remover</button>
          </div>
        )}
      </div> 
    </>
  )
}

export default ModalGetBalanceHistory