import { login } from "../../../api/accessControlApi";
import { ROLES } from "../../../constants/generals";
import { setCookie } from "../../../utils/sessionCookie";
import { encryptPassword } from "../../../utils/general";

const useLogin = () => {

  const logIn = async (user, pass) => {

    const encryptedPass = encryptPassword(pass); 
    return await login(user, encryptedPass)
      .then(response => {
        const { status_Message: message } = response;
        const { role, auth_token: token } = response.body; 
        if (message === "valid user" && role === ROLES.ADMIN) {
          setCookie("accessToken", token);
          return true;
        }
      })
      .catch(error => { 
        return false;
      });
  }

  return {
    logIn
  }
}

export default useLogin;