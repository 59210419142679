
import axios from "axios";
const { REACT_APP_API_URL } = process.env;


export const getBalanceHistory  = (payload, accessToken) => {
  
  
    const request = {
      method: "POST",
      url: `${REACT_APP_API_URL}business/get-balance-history`,
      data: payload,
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        auth_token: accessToken,
      },
    };
    return axios(request);
  };
  