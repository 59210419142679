import React from "react";
import AppRouter from "./router/AppRouter";
import { ActiveItemProvider } from "./context/ActiveItemContext";
import { Toaster } from "react-hot-toast";
import NotificationProvider from "./context/NotificationContext";

const App = () => {
  return (
    <>
      <NotificationProvider>
        <ActiveItemProvider>
          <AppRouter />
          <Toaster />
        </ActiveItemProvider>
      </NotificationProvider>
    </>
  );
};

export default App;
