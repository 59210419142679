import { post } from "./apiMethods";

export const login = async (user, pass) => {
  const payload = {
    new_user_email: user,
    new_user_password: pass,
    enviroment: "admin",
  };

  try {
    const response = await post("auth/login", payload);
    return response;
  } catch (error) { 
    return { error: error };
  }
};
