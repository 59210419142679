import { removeCookie } from "./sessionCookie";
import CryptoJS from "crypto-js";

const { REACT_APP_ENCRYPT_KEY } = process.env;

export const handleLogout = () => {
  removeCookie("accessToken");
};

export const encryptPassword = (pwd) => {
  let newPassword = CryptoJS.SHA512(pwd, REACT_APP_ENCRYPT_KEY).toString();
  return newPassword;
};

export const formatCurrency = (n) => {
  let amount = 0
  if (typeof n !== "number") { amount = parseFloat(n); }
  if (isNaN(amount)) return n;

  return n.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}