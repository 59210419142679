const mapToArray = (Array) => {
    return Array?.map((object) => {
      return [
        object.id_guide,
        object.tracking_code,
        object.zone,
        object.area,
        object.sector,
        object.subsector,
        // object.street_name,
        object.colony,
        object.postal_code,
        object.ext_number,
        object.package_weight,
        object.creation_date,
      ]
    });
    
  }

  
  const mapper = (d) => {
    const headers = [
      "",
      "CODIGO",
      "ZONA",
      "AREA",
      "SECTOR",
      "SUB",
      // "CALLE",
      "COLONIA",
      "CP",
      "# EXT",
      "PESO",
      "FECHA CREACION",
    ];
  
    const  guides = mapToArray(d);
  
    return {
      headers,
      guides
    }
  }
  
  export default mapper;