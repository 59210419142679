// import ArrowDown from "../../../../assets/icons/chevron-down.svg";
import ArrowLeft from "../../../../assets/icons/doublearrow-left.svg";
import ArrowRight from "../../../../assets/icons/doublearrow-right.svg";
import useWindowSize from "../../../../hooks/useWindowSize";
import ui from "./index.module.css";

const Pagination = ({
  count,
  currentPage,
  offset,
  setOffset,
  setCurrentPage,
}) => {
  const {
    width,
    // height
  } = useWindowSize();

  const BreakLine = () => {
    return width <= 834 ? <br /> : null;
  };
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(count / offset); i++) {
    pageNumbers.push(i);
  }

  const onPreviusPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const onNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const onSpecificPage = (n) => {
    setCurrentPage(n);
  };

  const numPages = count / offset;

  const handleSelectChange = (e) => {
    const selectedValue = parseInt(e.target.value); // Parsea el valor a entero
    setOffset(selectedValue);
  };

  return (
    <div className={ui.tableFooterTools}>
      <div className={ui.countRegs}>
        <p>Mostrando</p>
        <select name="rows" id={ui.selectRows} value={offset} onChange={handleSelectChange}>
          <option value={10}>10 renglones</option>
          <option value={20}>20 renglones</option>
          <option value={50}>50 renglones</option>
          <option value={100}>100 renglones</option>
        </select>
      </div>
      <div className={ui.pagination}>
        <button
          type="button"
          className={ui.paginationCircleArrows}
          disabled={currentPage === 1}
          onClick={onPreviusPage}
        >
          <img src={ArrowRight} alt="next" />
        </button>
        {pageNumbers.map((noPage) => (
          <button
            key={noPage}
            type="button"
            className={` ${
              noPage === currentPage
                ? ui.paginationCircleActive
                : ui.paginationCircle
            }`}
            disabled={numPages <= 1}
            onClick={() => onSpecificPage(noPage)}
          >
            <span>{noPage}</span>
          </button>
        ))}
        <button
          type="button"
          className={ui.paginationCircleArrows}
          disabled={currentPage >= pageNumbers.length}
          onClick={onNextPage}
        >
          <img src={ArrowLeft} alt="prev" />
        </button>
      </div>
      <div className={ui.showRegs}>
        <p>
          {offset > count ? count : offset} de {count} <BreakLine />
          <strong>renglones</strong>
        </p>
      </div>
    </div>
  );
};

export default Pagination;
