import React from "react";
import styled, { keyframes } from "styled-components";

const wheelSkAnimation = keyframes`
  0%, 50%, 100% { transform: translateY(0); }
  30%, 90% { transform: translateY(-1.5px); }
`;

const lineDroppingAnimation = keyframes`
  0% {
    background-position: 65px 11px, 82.5px 35px, 77.5px 60px;
    opacity: 1;
  }
  50% { background-position: 0px 11px, 10px 35px, 2.5px 60px; }
  60% { background-position: -15px 11px, 0px 35px, -5px 60px; }
  75%, 100% {
    background-position: -15px 11px, -15px 35px, -15px 60px;
    opacity: 0;
  }
`;

const LoaderContainer = styled.span`
  position: relative;
  width: 65px;
  height: 50px;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      90deg,
      rgba(228, 68, 68, 1) 0%,
      rgba(201, 16, 66, 1) 99%
    ),
    linear-gradient(#29b6f6, #4fc3f7), linear-gradient(#29b6f6, #4fc3f7);
  background-size: 40px 35px, 15px 25px, 15px 15px;
  background-position: 0 0, 40px 10px, 50px 20px;
`;

const Circle = styled.span`
  content: "";
  position: absolute;
  bottom: 5px;
  left: 6px;
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
  box-sizing: content-box;
  border: 5px solid #000;
  box-shadow: 39px 0 0 -5px #fff, 39px 0 #000;
  animation: ${wheelSkAnimation} 0.375s ease-in infinite alternate;
`;

const Line = styled.span`
  content: "";
  position: absolute;
  right: 100%;
  top: 0px;
  height: 35px;
  width: 35px;
  background-image: linear-gradient(
      90deg,
      rgba(250, 148, 186, 1) 0%,
      rgba(217, 29, 29, 1) 100%
    ),
    linear-gradient(90deg, rgba(250, 148, 186, 1) 0%, rgba(217, 29, 29, 1) 100%),
    linear-gradient(90deg, rgba(250, 148, 186, 1) 0%, rgba(217, 29, 29, 1) 100%);
  background-repeat: no-repeat;
  background-size: 15px 2px;
  background-position: 0px 5.5px, 4px 17.5px, 0px 30px;
  animation: ${lineDroppingAnimation} 0.400s linear infinite;
`;

const Loader = () => {
  return (
    <LoaderContainer className="loader">
      <Circle />
      <Line />
    </LoaderContainer>
  );
};

export default Loader;
