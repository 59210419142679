import axios from "axios";
import { getCookie } from "../../utils/sessionCookie";
const { REACT_APP_API_URL } = process.env;

const accessToken = getCookie("accessToken")

export const getClientsInformation = (payload) => {
  const request = {
    method: "GET",
    url: `${REACT_APP_API_URL}admin/get-business-list`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      auth_Token: accessToken,
    },
  };
  return axios(request);
};
