import React, { useState } from "react";
import styled, { css } from "styled-components";
import AddCreditsModal from "../AddCreditsModal";
import Button from "../../../components/sharedComponents/Button";
import closeModalIcon from "../../../assets/icons/CloseIcon.svg";
import {
  addCreditsToClient,
  removeCreditsToClient,
} from "../../../api/clientsApi";

const ModalContainer = styled.div`
  width: 34.375rem;
  height: 21.25rem;
  border-radius: 0.625rem;
  padding: 2rem;
`;

const ModalTitle = styled.h1`
  font-family: MontserratRegular;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: normal;
`;

const ModalDescription = styled.p`
  font-family: MontserratRegular;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 28rem;
  ${({ disabled }) =>
    disabled &&
    css`
      color: rgba(0, 0, 0, 0.8);
    `}
  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}rem;
    `}
`;

const CreditsInput = styled.input`
  height: 2rem;
  width: 15rem;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
  font-family: MontserratRegular;
  font-size: 1.5rem;
  padding-left: 0.5rem;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;

const BoldText = styled.span`
  font-family: MontserratSemibold;
  font-size: 0.8rem;
`;

const AddCreditsButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
`;

const CloseIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  width: 15px;
  heigth: 15px;
`;

const AddOrRemoveCreditsContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const ButtonsActionsContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 30px;
`;

const CreditFlowModal = (props) => {
  const { currentClientInfo, handleCreditFlowModalClose } = props;
  const [userCreditsToAdd, setUserCreditsToAdd] = useState(null);
  const [openConfirmModalForm, setOpenConfirmModalForm] = useState(false);
  const [addCreditsButtonBlocked, setAddCreditsButtonBlocked] = useState(false);
  const [removeCredits, setRemoveCredits] = useState(false);
  const [addCredits, setAddCredits] = useState(false);

  const handleOpenConfirmModal = () =>
    setOpenConfirmModalForm(!openConfirmModalForm);

  const handleChangeCredits = (credits) => {
    if (/^\d*$/.test(credits) || credits === "") {
      setUserCreditsToAdd(credits);
    }
  };

  const handleAddCreditsRender = () => setAddCredits(true);
  const handleRemoveCreditsRender = () => setRemoveCredits(true);
  const handleBackToMainPage = () => {
    setAddCredits(false);
    setRemoveCredits(false);
    setUserCreditsToAdd(0);
  };

  const handleAddCredits = () => {
    setAddCreditsButtonBlocked(true);
    const response = addCreditsToClient(
      currentClientInfo.clientId,
      userCreditsToAdd
    );
    return response;
  };
  const handleRemoveCredits = () => {
    setAddCreditsButtonBlocked(true);
    const response = removeCreditsToClient(
      currentClientInfo.clientId,
      userCreditsToAdd
    );
    return response;
  };

  const disableButton = parseInt(userCreditsToAdd) > 0;

  return (
    <ModalContainer>
      <CloseIconContainer>
        <Icon
          src={closeModalIcon}
          alt="close-icn"
          onClick={handleCreditFlowModalClose}
        />
      </CloseIconContainer>

      {!addCredits && !removeCredits ? (
        <>
          <AddOrRemoveCreditsContainer>
            <ModalTitle>¿Que deseas realizar?</ModalTitle>
            <ModalDescription mt={1}>
              Indica el tipo de acción que deseas ejecutar en el usuario
            </ModalDescription>

            <ButtonsActionsContainer>
              <Button
                id="add-credits"
                text="Agregar"
                variant="secondary"
                size="lg"
                handleClick={handleAddCreditsRender}
              />
              <Button
                id="remove-credits"
                text="Remover"
                variant="secondary"
                size="lg"
                handleClick={handleRemoveCreditsRender}
              />
            </ButtonsActionsContainer>
          </AddOrRemoveCreditsContainer>
        </>
      ) : (
        <>
          <ModalTitle>{addCredits ? "Agregar" : "Remover"} Créditos</ModalTitle>
          {/* <ModalTitle>Agregar Créditos</ModalTitle> */}
          <ModalDescription disabled={true}>
            Asegurate de que el usuario que hayas seleccionado, es el usuario
            correcto, antes de realizar cualquier transacción
          </ModalDescription>
          <ModalDescription disabled={false} mt={2.44}>
            Coloca la cantidad de créditos a{" "}
            {addCredits ? "agregar" : "remover"}
            {/* Coloca la cantidad de créditos a agregar */}
          </ModalDescription>
          <CreditsInput
            onChange={(e) => handleChangeCredits(e.target.value)}
            value={userCreditsToAdd}
          />
          <ModalDescription disabled={false} mt={2}>
            Se {addCredits ? "agregarán" : "removerán"}
            {/* Se agregarán */}
            {"   "}
            <BoldText>
              {" "}
              {userCreditsToAdd > 0 ? userCreditsToAdd : "0"}{" "}
            </BoldText>{" "}
            créditos al usuario{" "}
            <BoldText>{`${currentClientInfo.clientName}`}</BoldText>
          </ModalDescription>

          <AddCreditsButtonContainer>
            <Button
              text="Regresar"
              variant="outlined"
              size="sm"
              handleClick={handleBackToMainPage}
              mr={1}
            />
            <Button
              text={addCredits ? "Agregar" : "Remover"}
              variant="secondary"
              size="md"
              handleClick={handleOpenConfirmModal}
              disabled={!disableButton}
            />
          </AddCreditsButtonContainer>
        </>
      )}

      <AddCreditsModal
        addingCredits={addCredits}
        isAddModalOpen={openConfirmModalForm}
        UpdateConfirmModalForm={setOpenConfirmModalForm}
        closeCreditFlowModal={handleCreditFlowModalClose}
        handleAddCredits={handleAddCredits}
        handleRemoveCredits={handleRemoveCredits}
        blockAddCreditsButton={addCreditsButtonBlocked}
      />
    </ModalContainer>
  );
};

export default CreditFlowModal;