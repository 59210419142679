import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { COLORS } from "../../../constants/theme";

const Button = ({
  id,
  text,
  variant,
  size,
  handleClick,
  ml,
  mr,
  mt,
  disabled,
}) => {
  const handleActionClickButton = (e) => {
    e.preventDefault();
    handleClick();
  };

  const StyledButton = styled.button`
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.63rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-family: MontserratBold;
    margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}rem` : `0rem`)};
    margin-left: ${({ marginLeft }) =>
      marginLeft ? `${marginLeft}rem` : `0rem`};
    margin-right: ${({ marginRight }) =>
      marginRight ? `${marginRight}rem` : `0rem`};
    ${(props) =>
      props.disabled
        ? css`
            background-color: lightgray;
            color: #666;
            cursor: not-allowed;
          `
        : css`
            background-color: ${(props) =>
              props.variant === "primary"
                ? COLORS.MAIN
                : props.variant === "secondary"
                ? "#e74c3c"
                : "transparent"};
            color: ${(props) =>
              props.variant === "primary" || props.variant === "secondary"
                ? COLORS.WHITE
                : COLORS.MAIN_BLACK};
            border: ${(props) =>
              props.variant === "outlined"
                ? `1px solid ${COLORS.RED_LIGHT}`
                : "none"};

            &:hover {
              background-color: ${(props) =>
                props.variant === "primary"
                  ? COLORS.MAIN_BLACK
                  : props.variant === "secondary"
                  ? "#c0392b"
                  : "#transparent"};
              color: ${(props) =>
                props.variant === "outlined"
                  ? COLORS.MAIN_BLACK
                  : props.variant === "primary" || props.variant === "secondary"
                  ? "white"
                  : "#fff"};
            }
          `}

    ${(props) =>
      props.size === "sm" &&
      css`
        width: 8rem;
        height: 2.5rem;
      `}
            ${(props) =>
      props.size === "md" &&
      css`
        width: 10rem;
        height: 2.5rem;
      `}
            ${(props) =>
      props.size === "lg" &&
      css`
        width: 12rem;
        height: 2.5rem;
      `}
            ${(props) =>
      props.size === "xl" &&
      css`
        width: 14rem;
        height: 3rem;
      `};
  `;
  return (
    <StyledButton
      variant={variant}
      size={size}
      onClick={handleActionClickButton}
      marginLeft={ml}
      marginRight={mr}
      marginTop={mt}
      disabled={disabled}
      id={id}
    >
      {text}
    </StyledButton>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["primary", "secondary", "outlined"]).isRequired,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]).isRequired,
  handleClick: PropTypes.func.isRequired,
  ml: PropTypes.number,
  mr: PropTypes.number,
};

export default Button;
