import NextArrow from "../../../assets/icons/NextArrow.svg"
import ui from "../index.module.css";

const useComponent = ({ selectToggleModal }) => {

  const thereIsComponent = (index) => {

    return (
      <td>
        <div className={ui.actionTools}>
          <button type="button" className={ui.toolButton} onClick={() => {
            selectToggleModal(7, true, index)
          }}>
            <img src={NextArrow} alt="view"  className={ui.nextArrow}/>
          </button>
      
        </div>
      </td>
    )
  }

  return {
    thereIsComponent
  }
}

export default useComponent;