
import BlockIcon from "../../../assets/icons/block-icon.svg";
// import EditIcon from "../../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import ViewIcon from "../../../assets/icons/view-icon.svg";
import ui from "../index.module.css";

const useComponent = ({ selectToggleModal }) => {

  const thereIsComponent = (index) => {

    return (
      <td>
        <div className={ui.actionTools}>
          <button type="button" className={ui.toolButton} onClick={() => {
            selectToggleModal(4, true, index)
          }}>
            <img src={ViewIcon} alt="view" />
          </button>
          <button type="button" className={ui.toolButton} onClick={() => {
            selectToggleModal(5, true, index)
          }}>
            <img src={DeleteIcon} alt="delete" />
          </button>
          <button type="button" className={ui.toolButton} onClick={() => {
            selectToggleModal(6, true, index)
          }} >
            <img src={BlockIcon} alt="block" />
          </button>
        </div>
      </td>
    )
  }

  return {
    thereIsComponent
  }
}

export default useComponent;