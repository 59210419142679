import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { styled } from "styled-components";
import SearchInput from "../../components/SearchInput";
import ClientsTable from "../../components/ClientsTable";
import BodyModal from "../../components/sharedComponents/BodyModal";
import CreditFlowModal from "./CreditFlowModal";
import { getClientsInformation } from "../../api/v1/clientsApi";
// import { getClientsInformation } from "../../api/clientsApi";

const HeaderTitle = styled.h1`
  font-family: MontserratMedium;
  font-size: 2.25rem;
`;

const Credits = () => {
  const [creditFlowOpen, setCreditFlowOpen] = useState(false);
  const handleCreditFlowModalClose = () => setCreditFlowOpen(false);

  const openCreditFlowModalFromTableRow = () => {
    setCreditFlowOpen(true);
  };
  const [clientInformation, setClientInformation] = useState([]);
  const [currentUserToAddCredits, setCurrentUserToAddCredits] = useState({});
  const [loadingClientsInfo, setLoadingClientsInfo] = useState(false);
  const [filteredClientInfoRows, setFilteredClientInfoRows] =
    useState(clientInformation);

  const handleSetCurrentUserData = (payload) => {
    setCurrentUserToAddCredits(payload);
  };

  const handleFilterChange = (filteredRows) => {
    setFilteredClientInfoRows(filteredRows);
  };

  useEffect(() => {
    const fetchClientInfo = async () => {
      setLoadingClientsInfo(true);
      try {
        const response = await getClientsInformation();
        const { data } = response;
        setClientInformation(data.clients);
        setFilteredClientInfoRows(data.clients);
        setLoadingClientsInfo(false);
      } catch (error) {
        console.error(error);
        setLoadingClientsInfo(false);
      }
    };

    fetchClientInfo();
  }, []);

  return (
    <Layout>
      <HeaderTitle>Administrador de créditos</HeaderTitle>
      <SearchInput
        clientInfoRows={clientInformation}
        onFilterChange={handleFilterChange}
      />

      {filteredClientInfoRows && filteredClientInfoRows.length > 0 ? (
        <ClientsTable
          setIsAddCreditModalOpen={openCreditFlowModalFromTableRow}
          setCurrentClientInfo={handleSetCurrentUserData}
          clientInfoRows={filteredClientInfoRows}
          isLoadingData={loadingClientsInfo}
        />
      ) : (
        <p>Esperando datos de clientes...</p>
      )}
      <BodyModal
        dropShadow={true}
        open={creditFlowOpen}
        bodyHTML={
          <CreditFlowModal
            handleCreditFlowModalClose={handleCreditFlowModalClose}
            currentClientInfo={currentUserToAddCredits}
          />
        }
      />
    </Layout>
  );
};

export default Credits;
