import { useState, useEffect } from "react";
import mapper from "./mapper";
import axios from "axios";
import { getCookie } from "../../../utils/sessionCookie";
// import { filter } from "lodash";

const useFetch = () => {
  
  const [offset, setOffset] = useState(10);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [getData, setGetData] = useState([]);
  // const [refetch, setRefetch] = useState(null);
  const [loader, setLoader] = useState(false)
  const [searchTerm, setSearchTerm] = useState('');
  const [reloadData, setReloadData] = useState(true);

  const refetchData = () => setReloadData(true);


  useEffect(() => {
    async function httpRequest() {
      if (!reloadData) return;
      setLoader(true)
      const url = process.env.REACT_APP_API_URL + "admin/get-collect-batchs";
      const token = getCookie("accessToken");

      const headers = {
        headers: {
          "auth_token": token,
          "Content-Type": "application/json;charset=UTF-8"
        }
      }

      await axios
        .get(url, headers)
        .then(res => {
          if (res.data.status_Code === 200 && res.data.status_Message === "there are collect batchs") {
            const { data } = res;
            const total_batch =  data?.collect_batch.length;
            setCount(total_batch);
            setGetData(data.collect_batch);
            // setRefetch(false);
            setReloadData(false);
            setLoader(false);
        
          }
          if (res.data.status_Code === 200 && res.data.status_Message === "there aren't collect batchs") {
             setLoader(false)
          }
         
        })
        .catch(error => {
          setLoader(false)
          throw new Error('Error al intentar obtener las guías', error)
        })
    }
    
    httpRequest();
  }, [reloadData]);

  const { batchs, headers } = mapper(getData);


  return {
    batchs,
    headers,
    offset,
    setOffset,
    count,
    currentPage,
    setCurrentPage,
    refetchData,
    loader,
    searchTerm,
    setSearchTerm,
  }
}

export default useFetch;