import React, { useState } from 'react'
import Layout from '../../components/Layout'
import './index.css'
import SearchIcon from '../../assets/icons/search-icon.svg'
import { getCookie } from '../../utils/sessionCookie'
import { getDetailsGuide } from '../../api/getDetailsGuide'



const Tracking = () => {

    const [idSeguimiento, setIdSeguimiento] = useState('');
    
    const token =  getCookie('accessToken')
    const [guideInfo, setGuideInfo] = useState({})
    const [guidesHistory, setguidesHistory] = useState([])
    const [packageInfo, setPackageInfo] = useState({})
    const [originInfo, setOriginInfo] = useState({})
    const [destinyInfo, setDestinyInfo] = useState({})
    const [zoneInfo, setZoneInfo] = useState({})
    const [idIncompleted, setIdIncompleted] = useState(false)
    const handleInputChange = (event) => {
        setIdSeguimiento(event.target.value);
      };

    const handleGetTrackingInfo = () =>{
        if (idSeguimiento.length === 10) {
            const payload = {
                tracking_code : idSeguimiento
            }
            getDetailsGuide(payload, token)
            .then(res => {
                if (res.data.status_Code === 200 && res.data.status_Message === 'guide exist') {
                    const data = res.data
                    setGuideInfo(data.guide_info)
                    setPackageInfo(data.guide_info.package_info)
                    setOriginInfo(data.guide_info.origin_info)
                    setDestinyInfo(data.guide_info.destiny_info)
                    setguidesHistory(data.guides_history)
                    setZoneInfo(data.guide_info.zone_info)
                    setIdIncompleted(false)
                } else if(res.data.status_Code === 500){
                    setIdIncompleted(true)
                 
                }
            })
            .catch(error => {
                         setIdIncompleted(true)
                console.error('Error al realizar la petición:', error);
            });
        }else{
            setIdIncompleted(true)
            setPackageInfo({})
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleGetTrackingInfo(); 
        }
      };
    return (
        <Layout>
            <div className="container">
                <p className='title'>Detalle de guía</p>
                <p className='subTitle'>Ingresa el id de seguimiento de la guía</p>
                <div className='filterSearchBarTracking'>
                    <div className='searchBarTraking'>
                    <input 
                        maxLength={10}
                        type="text" 
                        placeholder="ID de seguimiento"
                        value={idSeguimiento}
                        onChange={handleInputChange} 
                        onKeyDown={handleKeyDown}
                    />
                    <div 
                        className='searchIconTracking' 
                        style={{ backgroundImage: `url(${SearchIcon})` }}
                        onClick={handleGetTrackingInfo}    
                    ></div>
                    </div>
                </div>
                {idIncompleted &&(
                    <>
                        <p className='trackinError'>Codigo de rastreo incorrecto</p>
                    </>
                )}
                {Object.keys(packageInfo).length > 0 &&(
                <>
                    <div className='containerName'>
                        <p className='titleName'>Responsable</p>
                        <div className='nameContainer'>
                            <p className='name'> {guideInfo.last_owner}</p>
                        </div>
                    </div>        
                    <div className="containerStateLast">
                        <div className="containerState">
                            <p className='titleState'>Estado de la guía</p>
                            <div className='containerTextState'>
                                <p className='state'>{guideInfo.status_keyname}</p>
                                <p className='subState'>{guideInfo.status_type}</p>
                            </div>
                        </div>
                        <div className="containerLast">
                            <p className='titleLastActualitation'>Ultima actualización</p>
                            <div className='containerTextLast'>
                                <p className='dateState'>{guideInfo.date}</p>
                            </div>
                            <div className="containerHours">
                                <div className='containerHour'>
                                    <p className='hour'>{guideInfo.time}</p>
                                </div>
                                <div className="containerFormateHour">
                                    <p className='format'>{guideInfo.time_type}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="containerDataPackage">
                        <div className="containerCol1">
                            <div className="containerMeasures">
                                <p className="titleCommon">Medidas del paquete</p>
                                <div className='measuresContainer'>
                                    <p className='measures'> {packageInfo.package_height} x {packageInfo.package_length} x {packageInfo.package_width} </p>
                                </div>
                            </div>
                            <div className="containerAreaZoneWeigth">
                                <div className='dataMeasures'>
                                    <p className="titleCommon">Peso(kg)</p>
                                    <div className='measuresContainerData'>
                                        <p className='measures'> {packageInfo.package_weight} </p>
                                    </div>
                                </div>
                                <div className='dataMeasures'>
                                    <p className="titleCommon">Zona</p>
                                    <div className='measuresContainerData'>
                                        <p className='measures'> {zoneInfo.zone}</p>
                                    </div>
                                </div>
                                <div className='dataMeasures'>
                                    <p className="titleCommon">Área</p>
                                    <div className='measuresContainerData'>
                                        <p className='measures'> {zoneInfo.area} </p>
                                    </div>
                                </div>
                            </div>
                            <div className="containerAreaZoneWeigth">
                                <div className='dataMeasures'>
                                    <p className="titleCommon">Sector</p>
                                    <div className='measuresContainerDataDown'>
                                        <p className='measures'> {zoneInfo.sector} </p>
                                    </div>
                                </div>
                                <div className='dataMeasures'>
                                    <p className="titleCommon">SubSector</p>
                                    <div className='measuresContainerDataDown'>
                                        <p className='measures'> {zoneInfo.subsector} </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="containerCol2">
                            <div className="containerOrigen">
                                <p className="titleCommonCol2">Origen</p>
                                <div className='origenContainer'>
                                    <p className='titleDirection'>Remitente</p>
                                    <p className='directionTitle'> {originInfo.sender} </p>
                                    <p className='titleDirection'>Dirección</p>
                                    <p className='directionText'> {originInfo.street_name}, {originInfo.colony} , Ext#{originInfo.ext_number}, CP:{originInfo.postal_code} </p>
                                </div>
                            </div>
                            <div className="containerOrigen">
                                <p className="titleCommonCol2">Destino</p>
                                <div className='origenContainer'>
                                    <p className='titleDirection'>Destinatario</p>
                                    <p className='directionTitle'> {destinyInfo.recipient} </p>
                                    <p className='titleDirection'>Dirección</p>
                                    <p className='directionText'> {destinyInfo.street_name}, {destinyInfo.colony} , Ext#{destinyInfo.ext_number}, CP:{destinyInfo.postal_code}  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="containerHistory">
                        <p className='titleHistoryDelivery'>Historial del paquete</p>
                        <div className="containerTimeLine">
                            <div class="timeline">
                                {guidesHistory.map((his, index) => (
                                <div class="entry" key={index}>
                                    <div class="title">
                                        <h3 className='fecha'>{his.date}</h3>
                                    </div>
                                    <div class="body">
                                        <p className='statusOfGuide'>{his.status}</p>
                                        <p className='hourStatusOfGuide'>{his.time}</p>
                                        {/* <p className='nameStatusOfGuide'>Juan Escutia</p> */}
                                    </div>
                                </div>
                                ))}



                            </div>
                        </div>

                    </div>
                </>
                )}

                
            </div>
        </Layout>
    )
}

export default Tracking