const mapToArray = (Array) => {
  return Array?.map((object) => {
    const full_name = [object.user_name, object.paternal_lastname, object.maternal_lastname].join(" ");
    return [
      object.id_user,
      full_name,
      object.user_state,
      object.role_type,
      object.register_date
    ]
  });
}

const mapper = (d) => {
  const headers = [
    "ID",
    "NOMBRE",
    "ESTADO",
    "TIPO",
    "FECHA CREACIÓN",
    "ACCIONES"
  ];

  const users = mapToArray(d);

  return {
    headers,
    users
  }
}

export default mapper;