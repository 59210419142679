import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import Home from "../pages/Home";
import { ROUTES } from "../constants/routes";
import Credits from "../pages/Credits";
import Users from "../pages/Users";
// import Packages from "../pages/Packages";
import Login from "../pages/Login";
import PrivateRoute from "../components/sharedComponents/PrivateRoute";
import PublicRoute from "../components/sharedComponents/PublicRoute";
import LoginPage from "../pages/V2/LoginPage";
import StatusPackage from "../pages/StatusPackage";
import Lotes from "../pages/Lotes";
import CreateReport from "../pages/CreateReport";
import Balance from "../pages/Balance";
import Tracking from "../pages/Tracking";
import DistributionAssingment from "../pages/DistributionAssignment";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route index path={ROUTES.LOGIN} element={<Login />} />
          <Route index path={ROUTES.LOGINV2} element={<LoginPage />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route index path={ROUTES.HOME} element={<Home />} />
          <Route path={ROUTES.USERS} element={<Users />} />
          <Route path={ROUTES.CREDITS} element={<Credits />} />
          <Route path={ROUTES.STATUS_PACKAGE} element={<StatusPackage />} />
          <Route path={ROUTES.LOTES} element={<Lotes />} />
          <Route path={ROUTES.CREATE_REPORT} element={<CreateReport />} />
          <Route path={ROUTES.BALANCE} element={<Balance />} />
          <Route path={ROUTES.TRACKING} element={<Tracking />} />
          <Route path={ROUTES.DISTRIBUTION_ASSINGMENT} element={<DistributionAssingment />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
