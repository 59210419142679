import SuccessIcon from "../../../assets/icons/check-circle.svg";
import CancelIcon from "../../../assets/icons/cancel-circle.svg";
import useWindowSize from "../../../hooks/useWindowSize";
import ui from "./index.module.css";

const Row = ({ rowIndex, row, headers, thereIsComponent = null }) => {
  const { width } = useWindowSize();

  // console.log(row)

  return width <= 480 ? (
    <tr className={ui.row}>
      <tr className={ui.rowTop}>
        <td>
          <span>{headers[1]}</span>
          <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            {row[1]}
          </span>
        </td>
        <td>
          <span>{headers[3]}</span>
          <span>{row[3]}</span>
        </td>
        {thereIsComponent ? thereIsComponent(row[0]) : null}
      </tr>
      <tr className={ui.rowBottom}>
        <td className={ui.cellStatus}>
          <span>{headers[2]}</span>
          {row[2] ? (
            <span datatype="success">Activo</span>
          ) : (
            <span datatype="cancel">Inactivo</span>
          )}
        </td>
        <td>
          <span style={{ whiteSpace: "nowrap" }}>{headers[4]}</span>
          <span style={{ whiteSpace: "nowrap" }}>{row[4]}</span>
        </td>
        <td></td>
      </tr>
    </tr>
  ) : (
    <tr>
      {row?.map((cell, index) => {
        if (index === 2) {
          return (
            <td key={index}>
              {cell ? (
                <img alt="success" src={SuccessIcon} />
              ) : (
                <img alt="cancel" src={CancelIcon} />
              )}
            </td>
          );
        }
        if (index === 3) {
          return (
            <td key={index}>{cell === "sysliber" ? "Administrador" : cell}</td>
          );
        }
        return <td key={index}>{cell}</td>;
      })}
      {thereIsComponent ? thereIsComponent(row[0]) : null}
    </tr>
  );
};

export default Row;
