import React from "react";
//Icons
import CreditIcon from "./icons/credits-icon.svg";
import PackageIcon from "./icons/packages-icon.svg";
import UserIcon from "./icons/users-icon.svg";
import DashboardIcon from "./icons/dashboard-icon.svg";
import LogoutIcon from "./icons/logout-icon.svg";
import BurgerIcon from "./icons/burger-icon.svg";
import CloseIcon from "./icons/close-icon.svg";
import imgArrowBottom from "./icons/arrowBottom.svg"
//logo 
import { ROUTES } from "../../../constants/routes";
import { handleLogout } from "../../../utils/general";
import { NavLink, Link, useNavigate } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import ui from "./index.module.css";
import { useState } from "react";

const Icon = ({ icon, name }) => <img className={ui.navIcon} alt={name} src={icon} />

const Drawer = () => {

  const [open, setOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);

  const handleNavLinkClick = (index) => {
    if (index === openIndex) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  const navlinks = [
    { icon: DashboardIcon, text: "Dashboard", isActive: true, route: ROUTES.HOME, item: "dashboard" },
    { icon: CreditIcon, text: "Créditos", isActive: false, route: ROUTES.CREDITS, item: "credits" },
    { icon: UserIcon, text: "Usuarios", isActive: false, route: ROUTES.USERS, item: "users" },
    { icon: PackageIcon, text: "Paquetes", isActive: false,  item: "packages",
    dropdown: [
      { text: "Lotes", route: ROUTES.LOTES },
      { text: "Recolección", route: ROUTES.CREATE_REPORT },
      { text: "Asignar guías", route: ROUTES.DISTRIBUTION_ASSINGMENT },
      { text: "Listado de Guías", route: ROUTES.STATUS_PACKAGE },
    ]  },
    { icon: UserIcon, text: "Consultas", isActive: false, item: "batch", 
    dropdown: [
      { text: "Creditos", route: "/",isActive: false, },
      { text: "Saldos", route: "/saldo", isActive: false, },
      { text: "Detalle guía", route: "/seguimiento", isActive: false, },
    ] },
  ]
  const navlinksMovil = [
    { icon: DashboardIcon, text: "Dashboard", isActive: true, route: ROUTES.HOME, item: "dashboard" },
    { icon: CreditIcon, text: "Créditos", isActive: false, route: ROUTES.CREDITS, item: "credits" },
    { icon: UserIcon, text: "Usuarios", isActive: false, route: ROUTES.USERS, item: "users" },

    { icon: UserIcon, text: "Lotes", isActive: false, route: ROUTES.LOTES, item: "lotes" },
    { icon: UserIcon, text: "Recolección", isActive: false, route: ROUTES.CREATE_REPORT, item: "lotes" },
    { icon: UserIcon, text: "Asignar guías", isActive: false, route: ROUTES.DISTRIBUTION_ASSINGMENT, item: "statusPackage" },
    { icon: UserIcon, text: "Listado de Guías", isActive: false, route: ROUTES.STATUS_PACKAGE, item: "statusPackage" },

    { icon: UserIcon, text: "Creditos", isActive: false, route: "/", item: "lotes" },
    { icon: UserIcon, text: "Saldos", isActive: false, route: "/saldo", item: "saldos" },
    { icon: UserIcon, text: "Detalle guía", isActive: false, route: "/seguimiento", item: "detalleGuia" },


  ]

  const { width } = useWindowSize();
  const navigate = useNavigate();

  return width <= 480 ?
    (
      <header id={ui.mobileDrawer}>
        <div>
          <button type="button" className={ui.mobileMenu} onClick={() => setOpen(!open)}>
            {
              open
                ? <img src={CloseIcon} alt="burgerMenu" />
                : <img src={BurgerIcon} alt="burgerMenu" />
            }
          </button>
          <div className={ui.drawerLogo}>
            <img src="https://assets-liber.s3.amazonaws.com/images/LogoLetrasLiber.svg" alt="logo" />
          </div>
        </div>
        <BurgerMenu list={navlinksMovil} open={open} handleToggle={() => setOpen(!open)} />
      </header>
    )
    : (
      <aside id={ui.drawer}>
        <div className={ui.drawerLogo}>
          <img className="" src="https://assets-liber.s3.amazonaws.com/images/LogoLetrasLiber.svg" alt="Logo" />
        </div>
        <section id={ui.drawerUser}>
          <div id={ui.userInfo}>
            <div className={ui.userIcon}>
              <span>LB</span>
            </div>
            <h1>Usuario</h1>
            <h2>Administrador</h2>
          </div>
        </section>
        <section id={ui.drawerNav}>
        <nav id={ui.navLinks}>
          {navlinks?.map((item, index) => (
            <div key={index} className={item.dropdown ? `${ui.hasDropdown} ${openIndex === index ? ui.open : ""}` : ""}>
              <NavLink
                className={ui.textLink}
                style={({ isActive }) => ({
                  // background: (isActive || openIndex === index) ? "#dc0e18" : "",
                })}
                to={item.route}
                onClick={() => {
                  if (item.dropdown) {
                    handleNavLinkClick(index);
                  }
                }}
              >
                <Icon icon={item.icon} name={item.item} />
                {item.text}
                {item.dropdown && <img src={imgArrowBottom} alt="Flecha abajo" className={ui.dropdownArrow} />}
              </NavLink>
              {item.dropdown && (
                <div className={ui.dropdown}>
                  {item.dropdown.map((option, optionIndex) => (
                    <NavLink
                      key={optionIndex}
                      to={option.route}
                      className={ui.dropdownOption}
                    >
                      {option.text}
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
          <div id={ui.closeSession}>
            <Link
              onClick={() => {
                handleLogout();
                navigate(ROUTES.LOGIN, { replace: true });
              }}
              to={ROUTES.LOGIN}
              className={ui.logout}
            >
              <img src={LogoutIcon} alt="logout" />
              Cerrar Sesión
            </Link>
          </div>
        </section>
      </aside>
    );
};

const BurgerMenu = ({ list, open }) => {

  return open ? (
    <div className={ui.mobileNavMenu}>
      <div>
        <ul className={ui.navItems}>
          {
            list?.map((i, index) => {
              const { text, route } = i;
              return (
                <li className={ui.itemNavLink} key={index}>
                  <NavLink
                    style={({ isActive }) => {
                      return { background: isActive ? "#dc0e18" : "" }
                    }}
                    to={route}
                  >
                    {text}
                  </NavLink>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  ) : null;
}


export default Drawer;