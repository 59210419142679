import { useEffect, useState } from "react";
import useAxios from "../ModalSucursal/hooks/useAxios";
import ui from "./index.module.css";
import { getCookie } from "../../../../utils/sessionCookie";
import axios from "axios";

const ModalViewUser = ({ selectToggleModal, type, reference }) => {

  const [infoPostalCode, setInfoPostalCode] = useState({
    id_postal_code: 0,
    country: "",
    state: "",
    city: "",
    colony: "",
    postal_code: 0,
  });

  const [formData, setFormData] = useState({
    user_name: "",
    paternal_lastname: "",
    maternal_lastname: "",
    telephone_number: "",
    email: "",
    password: "",
    id_postal_code: "",
    alias: "",
    state: "",
    city: "",
    colony: "",
    street_name: "",
    ext_number: "",
    int_number: "",
    references: "",
  })
  const [actionEdit, setActionEdit] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const {
    checkZipCodeExistance,
    getZipCodeInfo,
    // addWareHouse
  } = useAxios();

  useEffect(() => {

    const httpRequest = async () => {

      const url = process.env.REACT_APP_API_URL + "admin/get-info-users";
      const token = getCookie("accessToken");
      const headers = {
        "auth_token": token,
        "Content-Type": "application/json;charset=UTF-8"
      }

      const data = { id_user: reference };

      await axios
        .post(url, data, { headers })
        .then(response => {
          console.log({ response })
          const { info_user: i, status_Message: status } = response.data;
          if (status === "exist user")
            setFormData((prev) => {
              return {
                ...prev,
                user_name: i.user_name || "",
                paternal_lastname: i.paternal_lastname || "",
                maternal_lastname: i.maternal_lastname || "",
                telephone_number: i.telephone_number || "",
                email: i.user_email || "",
                id_postal_code: i.id_postal_code || "",
                alias: i.alias || "",
                state: i.state || "",
                city: i.city || "",
                colony: i.colony || "",
                street_name: i.street_name || "",
                ext_number: i.external_number || "",
                int_number: i.internal_number || "",
                references: i.location_references || ""
              }
            });
          setInfoPostalCode((prev) => {
            return {
              ...prev,
              id_postal_code: i.id_postal_code || 0,
              state: i.state || "",
              city: i.city || "",
              colony: i.colony || "",
              postal_code: i.postal_code || 0,
            }
          })
        })
        .catch(error => {
          return { response: false };
        });

    }

    httpRequest();
  }, [reference])

  const handleZipCode = async (e) => {
    const zipCode = e.target.value;

    if (zipCode.length < 5) return;
    const { validated: exist, id_postal_code } = await checkZipCodeExistance(zipCode)

    if (!exist) return;

    const zipCodeInfo = await getZipCodeInfo(zipCode);

    if (!zipCodeInfo) return;
    setInfoPostalCode({ ...zipCodeInfo, id_postal_code });
    setFormData((prev) => {
      return {
        ...prev,
        ...zipCodeInfo,
        id_postal_code
      }
    });
  }

  const handleOnChange = (e) => {
    setIsDisabled(false);
    const name = e.target.name;
    const value = e.target.value;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    window.alert("Cambios realizados");
    // setLoading(true);
    // const response = await addWareHouse(formData);
    // console.log({ response });
    // selectToggleModal(option, false)
    // setLoading(false);
    return;
  }

  return (
    <div className={ui.formContainer}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <h2>Información Personal</h2>
        <section className={ui.formCard}>
          <div className={ui.inputForm}>
            <label htmlFor="sucName">Nombre</label>
            <input type="text" name="user_name" id="sucName" defaultValue={formData.user_name} onChange={handleOnChange} disabled={!actionEdit} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucPatLastName">Apellido Paterno</label>
            <input type="text" name="paternal_lastname" id="sucPatLastName" defaultValue={formData.paternal_lastname} onChange={handleOnChange} disabled={!actionEdit} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucMatLastName">Apellido Materno</label>
            <input type="text" name="maternal_lastname" id="sucMatLastName" defaultValue={formData.maternal_lastname} onChange={handleOnChange} disabled={!actionEdit} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucPhone">Teléfono</label>
            <input type="text" name="telephone_number" id="sucPhone" defaultValue={formData.telephone_number} onChange={handleOnChange} disabled={!actionEdit} />
          </div>
          <div className={ui.inputForm} datatype="emailForm">
            <label htmlFor="sucEmail">Correo electrónico</label>
            <input autoComplete="off" type="email" name="email" defaultValue={formData.email} id="sucEmail" onChange={handleOnChange} disabled={!actionEdit} />
          </div>
          <div className={ui.inputForm} datatype="passForm">
            <label htmlFor="sucPass">Contraseña</label>
            <input autoComplete="new-password" type="password" name="password" id="sucPass" onChange={handleOnChange} disabled={!actionEdit} />
          </div>
        </section>
        <h2>Información Sucursal</h2>
        <section className={ui.formCard}>
          <div className={ui.inputForm} datatype="doubleSpace">
            <label htmlFor="sucAlias">Alias del domicilio</label>
            <input type="text" name="alias" id="sucAlias" defaultValue={formData.alias} onChange={handleOnChange} disabled={!actionEdit} style={{ maxWidth: "100%" }} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucZipCode">Código Postal</label>
            <input type="text" name="zipcode" id="sucZipCode" defaultValue={infoPostalCode.postal_code} onChange={(e) => handleZipCode(e)} disabled={!actionEdit} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucState">Estado</label>
            <input type="text" disabled name="state" id="sucState" defaultValue={infoPostalCode.state} onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucCol">Colonia</label>
            <input type="text" name="colony" id="sucCol" defaultValue={infoPostalCode.colony} onChange={handleOnChange} disabled={!actionEdit} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucCity">Ciudad</label>
            <input type="text" name="city" id="sucCity" defaultValue={infoPostalCode.city} onChange={handleOnChange} disabled={!actionEdit} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucStreet">Calle</label>
            <input type="text" name="street_name" id="sucStreet" defaultValue={formData.street_name} onChange={handleOnChange} disabled={!actionEdit} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucNoExt">Número Externo</label>
            <input type="text" name="ext_number" id="sucNoExt" defaultValue={formData.ext_number} onChange={handleOnChange} disabled={!actionEdit} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucNoInt">Número Interno</label>
            <input type="text" name="int_number" id="sucNoInt" defaultValue={formData.int_number} onChange={handleOnChange} disabled={!actionEdit} />
          </div>
          <div className={ui.textAreaForm}>
            <label htmlFor="sucReference">Referencias del lugar</label>
            <textarea name="references" id="sucReference" defaultValue={formData.references} onChange={handleOnChange} disabled={!actionEdit} />
          </div>
        </section>
        <div className={ui.formFooterTools}>
          {/* <Switch
            handleSwitch={() => setActionEdit(!actionEdit)}
            active={actionEdit}
          /> */}
          <ActionButtons
            selectToggleModal={selectToggleModal}
            isDisabled={isDisabled}
            actionEdit={actionEdit}
            type={type}
          />
        </div>
      </form>
    </div>
  )
}

const ActionButtons = ({ selectToggleModal, isDisabled, actionEdit, type }) => {
  return actionEdit ? (
    <>
      <button type="button" className={ui.cancelButton} onClick={() => selectToggleModal(type, false)}>
        <span>Cancelar</span>
      </button>
      <button type="submit" className={ui.createButton} disabled={isDisabled}>
        <span>Crear</span>
      </button>
    </>
  ) : null
}

const Switch = ({
  handleSwitch = () => { },
  active = false
}) => {
  return (
    <div className={ui.switchContainer}>
      <p>Editar Usuario</p>
      <div>
        <label className={ui.switch}>
          <input type="checkbox" checked={active} onChange={handleSwitch} />
          <span className={ui.sliderRound}></span>
        </label>
      </div>
    </div>
  )
}

export default ModalViewUser;