import { useState, useEffect } from "react";
import mapper from "./mapper";
import axios from "axios";
import { getCookie } from "../../../utils/sessionCookie";

const useFetch = () => {
  
  const [offset, setOffset] = useState(10);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [getData, setGetData] = useState([]);
  const [refetch, setRefetch] = useState(null);
  const [loader, setLoader] = useState(false)

  const refetchData = () => setRefetch(!refetch);

  useEffect(() => {

    async function httpRequest() {
      setLoader(true)
      if (refetch === false) return;

      const url = process.env.REACT_APP_API_URL + "admin/get-guides-orders";
      const token = getCookie("accessToken");

      const headers = {
        headers: {
          "auth_token": token,
          "Content-Type": "application/json;charset=UTF-8"
        }
      }

      await axios
        .get(url, headers)
        .then(response => {
          const { data } = response;
          const total_guides = data?.orders === undefined ? 0 : data?.orders.length;
          if (total_guides === 0) return [];
          setCount(total_guides);
          setGetData(data.orders);
          setRefetch(false);
          setTimeout(() => {
            setLoader(false)
          }, 500);
        
        })
        .catch(error => {
          return []
        });
    }

    httpRequest();
  }, [refetch]);


  const { orders, headers } = mapper(getData);

  return {
    orders,
    headers,
    offset,
    setOffset,
    count,
    currentPage,
    setCurrentPage,
    refetchData,
    loader
  }
}

export default useFetch;