import React, { useContext } from "react";
import useFetch from "./hooks/useFetch";
import useAction from "../Users/hooks/useAction";
import { NotificationContext } from "../../context/NotificationContext";
import Layout from "../../components/Layout";
import BasicTable from "./BasicTable/index";
import Pagination from "./BasicTable/Pagination/index";
import Modal from "../../components/ModalWrapper";
import ModalResponse from "../../components/ModalResponse";
import ui from './StatusPackage.css'
import SearchIcon from '../../assets/icons/search-icon.svg'
import useComponent from "./hooks/useComponent";

const StatusPackage  = () => {

  const {
    orders,
    headers,
    limit,
    offset,
    setOffset,
    currentPage,
    setCurrentPage,
    count,
    refetchData
  } = useFetch();

  const {
    selectToggleModal,
    getModalStatus
  } = useAction();
  
 
  const {
    thereIsComponent
  } = useComponent({ selectToggleModal });

  const {
    notification,
    notificationType
  } = useContext(NotificationContext);

  const lastIndex = currentPage * offset
  const firstIndex = lastIndex - offset

  return (
    <Layout>
      <div id='page'>
        <h1>Resumen de las guías</h1>
        <p>Resumen de guías generadas. Cambia el estado de cualquiera de ellas.</p>
        <section className='body'>
          <div className='container'>
            <div className='containerFilters'>
              <div className='filterSearchBar'>
                <div className='searchBar'>
                  <input type="text" placeholder="Buscar usuario" />
                  <div className='searchIcon' style={{ backgroundImage: `url(${SearchIcon})` }}></div>
                </div>
              </div>
            </div>
            <div className={ui.containerTable}>
              <BasicTable
                information={orders}
                headers={headers}
                thereIsComponent={thereIsComponent}
                lastIndex={lastIndex}
                firstIndex={firstIndex}
              />
              <Pagination
                count={count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limit={limit}
                offset={offset}
                setOffset={setOffset}
              />
            </div>
          </div>
        </section>
      </div>
      <Modal
        getModalStatus={getModalStatus}
        selectToggleModal={selectToggleModal}
      />
      {
        notification
          ? <ModalResponse notificationType={notificationType} refetchData={refetchData} />
          : null
      }
    </Layout>
  );
};

export default StatusPackage ;