// import { useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import ui from "./index.module.css";

const Row = ({row, headers, isSelected, thereIsComponent = null, handleCheckClick}) => {
  const { width } = useWindowSize();

  // const [isSelected, setIsSelected] = useState(false);


  const handleCheckRow = () => {
    // setIsSelected(!isSelected);
    handleCheckClick(row[0])
  };

  return width <= 480 ? (
    <tr className={ui.row}>
      <tr className={ui.rowTop}>


        <td>
          <span>{headers[1]}</span>
          <span>{row[1]}</span>
        </td>
        <td>
          <span>{headers[2]}</span>
          <span>{row[2]}</span>
        </td>
        <td>
          <span>{headers[3]}</span>
          <span>{row[3]}</span>
        </td>
        <td>
          <span>{headers[4]}</span>
          <span>{row[4]}</span>
        </td>
        <td>
          <span>{headers[5]}</span>
          <span>{row[5]}</span>
        </td>
        <td></td>
 
      </tr>
      <tr className={ui.rowBottom}>
        <td>
          <span>{headers[6]}</span>
          <span>{row[6]}</span>
        </td>
        <td>
          <span>{headers[7]}</span>
          <span>{row[7]}</span>
        </td>
        <td>
          <span>{headers[8]}</span>
          <span>{row[8]}</span>
        </td>
        <td>
          <span>{headers[9]}</span>
          <span>{row[9]}</span>
        </td>
        <td>
          <span>{headers[10]}</span>
          <span>{row[10]}</span>
        </td>
        <td>
          <input type="checkbox" checked={isSelected} onChange={handleCheckRow} />
        </td>

      </tr>

    </tr>
  ) : (
    <tr>
      <td>
        <input type="checkbox" checked={isSelected} onChange={handleCheckRow} />
      </td>

      {row?.map((cell, index) => {
        if (index === 0) {
          return null
        }
        return <td key={index}>{cell}</td>;
      })}
    </tr>
  );
};

export default Row;
