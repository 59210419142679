import AdminIcon from "../../assets/icons/admin-modal-icon.svg";
import RepartidorIcon from "../../assets/icons/repartidor-modal-icon.svg";
import SucursalIcon from "../../assets/icons/sucursal-modal-icon.svg";
import ui from "./index.module.css";

const ModalContent = ({ selectToggleModal}) => {
  return (
    <div className={ui.userOptions}>
      <button type="button" className={ui.optionButton} onClick={() => selectToggleModal(1, true)}>
        <img src={AdminIcon} alt="admin" />
        <span>Administrador</span>
      </button>
      <button type="button" className={ui.optionButton} onClick={() => selectToggleModal(2, true)}>
        <img src={RepartidorIcon} alt="repartidor" />
        <span>Repartidor</span>
      </button>
      <button type="button" className={ui.optionButton} onClick={() => selectToggleModal(3, true)}>
        <img src={SucursalIcon} alt="sucursal" />
        <span>Sucursal</span>
      </button>
    </div>
  )
}

export default ModalContent;