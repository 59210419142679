import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import ui from "./index.module.css"; 
import LiberLogoIcon from "../../assets/images/liber-icon-logo.png";
import useLogin from "./hooks/useLogin";
import LoadingIcon from "../../assets/icons/loading-icon.svg";

const Login = () => {

  const [errorUserMessage, setErrorUserMessage] = useState(false);
  const [errorPassMessage, setErrorPassMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const inputUser = useRef("");
  const inputPass = useRef("");

  const navigate = useNavigate();
  const { logIn } = useLogin();

  const handleSubmit = async (e) => {

    e.preventDefault();
    setLoading(true);

    const user = inputUser.current.value;
    const pass = inputPass.current.value;

    const res = await logIn(user, pass);
    if (res) {
      setLoading(false);
      navigate(ROUTES.HOME, { replace: true });
      return;
    }
    setLoading(false);
    setErrorPassMessage(true);
    setErrorUserMessage(true);
  }

  return (
    <main>
      <section id={ui.wrapper}>
        <div className={ui.bgLeftImage} style={{ backgroundImage: `url(https://assets-liber.s3.amazonaws.com/images/BackgroundGirl.png)` }}>
          <div className={ui.bgLeftTitles}>
            <h1>Siempre la mejor opción</h1>
            <h2>Rapida, segura y eficiente.</h2>
          </div>
        </div>
        <div className={ui.bgRightForm}>
          <div className={ui.logoIcon} style={{ backgroundImage: `url(${LiberLogoIcon})` }}></div>
          <div className={ui.formContainer}>
            <div>
              <h1>¡Bienvenido!</h1>
              <p>Ingresa tu información de usuario</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className={ui.inputForm}>
                <label htmlFor="new_user_email">Usuario</label>
                <input type="email" name="new_user_email" id="new_user_email" placeholder="Usuario" ref={inputUser} autoComplete="off" />
                {
                  errorUserMessage
                    ? <span>Usuario incorrecto</span>
                    : null
                }
              </div>
              <div className={ui.inputForm}>
                <label htmlFor="new_user_password">Contraseña</label>
                <input type="password" name="new_user_password" id="new_user_password" placeholder="Contraseña" ref={inputPass} autoComplete="off" />
                {
                  errorPassMessage
                    ? <span>Contraseña incorrecta</span>
                    : null
                }
              </div>
              <button type="submit">
                {
                  loading
                    ? <><span style={{ color: "white" }}>Cargando...</span> <img src={LoadingIcon} className={ui.loadingIcon} alt="loading" /></>
                    : <span>Iniciar Sesión</span>
                }


              </button>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Login;
