import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_API_URL } = process.env;


export const collectionReport = (payload) => {
  
  const request = {
    method: "POST",
    data: payload,
    url: `${REACT_APP_API_URL}extras/create-daily-report`,
    headers: {
      "content-type": "application/json",
       auth_token: getCookie("accessToken"),
    },
  };

  return axios(request);
};
export const collectionReportExist = (payload) => {
  const request = {
    method: "GET",
    data: payload,
    url: `${REACT_APP_API_URL}extras/exist-daily-report`,
    headers: {
      "content-type": "application/json",
       auth_token: getCookie("accessToken"),
    },
  };


  return axios(request);
};
