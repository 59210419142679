import LoaderTable from "../../../components/animations/loaderTable";
import useFetch from "../hooks/useFetch";
import ui from "./index.module.css";
import Row from "./row";
// import useWindowSize from "../../../hooks/useWindowSize"; 



const BasicTable = ({information = [],headers = [],firstIndex,lastIndex, handleCheckClick, selectedRowsToAssigned}) => {

  const {
    loader,
  } = useFetch();


  const handleRowSelect = (rowId) => {
    handleCheckClick(rowId)
  };

  return (
    <>
      <div id={ui.tableContainer}>
        <table id={ui.basicTable}>
          <thead>
            <tr>{headers?.map((head, index) => <th key={index}>{head}</th>)}</tr>
          </thead>
          <tbody>
            {loader ? ( 
            <> 
              <tr>
                <td colSpan={headers.length} style={{ textAlign: 'center' }}>
                  <div className={ui.containerLoader}>
                    <LoaderTable/>
                  </div> 
                </td>
              </tr>
            </>
            ):(
              information.length > 0 ? 
              information?.map((row, index) => {
                const isSelected = selectedRowsToAssigned.includes(row[0]);
                return (
                  <Row
                    key={index}
                    rowIndex={index}
                    row={row}
                    headers={headers}
                    isSelected={isSelected}
                    handleCheckClick={handleRowSelect}
                  />
                )
              }).slice(firstIndex, lastIndex)
              :
              <> 
              <tr>
                <td colSpan={headers.length} style={{ textAlign: 'center' }}>
                  <div className={ui.containerLoader}>
                    No existen guías por mostrar
                  </div> 
                </td>
              </tr>
            </>
            )}
          </tbody>
        </table>
      </div>

    </>
  )
}

export default BasicTable;