export const ROUTES = {
    HOME : "/",
    LOGIN : "/iniciarSesion",
    USERS: "/usuarios",
    CREDITS : "/creditos",
    LOGINV2: "/loginv2",
    STATUS_PACKAGE: "/statusPackage",
    CREATE_REPORT: "/generarReporte",
    LOTES: "/lotes",
    BALANCE: "/saldo",
    TRACKING: "/seguimiento",
    DISTRIBUTION_ASSINGMENT: "/asignamientoReparto"
}