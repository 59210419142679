import ui from "./index.module.css";
import { useState } from "react";
import useAxios from "./hooks/useAxios";
import { useContext } from "react";
import { NotificationContext } from "../../../../context/NotificationContext";
import { useEffect } from "react";

const ModalSucursal = ({ selectToggleModal, select }) => {

  const [infoPostalCode, setInfoPostalCode] = useState({
    id_postal_code: "",
    country: "",
    state: "",
    city: "",
    colony: "",
    postal_code: "",
  });

  const [formData, setFormData] = useState({
    user_name: "",
    paternal_lastname: "",
    maternal_lastname: "",
    telephone_number: "",
    email: "",
    password: "",
    id_postal_code: "",
    alias: "",
    state: "",
    city: "",
    colony: "",
    street_name: "",
    ext_number: "",
    int_number: "",
    references: ""
  });

  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);


  const { checkZipCodeExistance, getZipCodeInfo, addWareHouse } = useAxios();
  const { handleShowNotification } = useContext(NotificationContext);

  const handleZipCode = async (e) => {
    const zipCode = e.target.value;

    if (zipCode.length < 5) return;
    const { validated: exist, id_postal_code, response: res } = await checkZipCodeExistance(zipCode)

    if (!exist || !res) {
      handleShowNotification(exist);
      return;
    }

    const { info_postal_code, response } = await getZipCodeInfo(zipCode);

    if (!response) {
      handleShowNotification(response);
      return;
    };

    setInfoPostalCode({ ...info_postal_code, id_postal_code });
    setFormData((prev) => {
      return {
        ...prev,
        ...info_postal_code,
        id_postal_code
      }
    });
  }

  useEffect(() => {

    const handleEffect = () => {

      const dataList = Object.entries(formData);
      const list = dataList?.map(item => item[1] === "");
      const isEmpty = list.some(item => item === true); 
      if (isEmpty === true) return;
      setIsDisabled(false);
      return;
    }

    handleEffect();

  }, [formData])

  const handleOnChange = (e) => {

    const name = e.target.name;
    const value = e.target.value;
    if (value === "" && isDisabled === false) {
      setIsDisabled(true);
      return;
    }

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value
      }
    });

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { response } = await addWareHouse(formData);
    setLoading(false);
    selectToggleModal(select, false)
    handleShowNotification(response);
    return;
  }

  return (
    <div className={ui.formContainer}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <h2>Información Personal</h2>
        <section className={ui.formCard}>
          <div className={ui.inputForm}>
            <label htmlFor="sucName">Nombre</label>
            <input type="text" name="user_name" id="sucName" onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucPatLastName">Apellido Paterno</label>
            <input type="text" name="paternal_lastname" id="sucPatLastName" onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucMatLastName">Apellido Materno</label>
            <input type="text" name="maternal_lastname" id="sucMatLastName" onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucPhone">Teléfono</label>
            <input type="text" name="telephone_number" id="sucPhone" onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm} datatype="emailForm">
            <label htmlFor="sucEmail">Correo electrónico</label>
            <input autoComplete="off" type="email" name="email" id="sucEmail" onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm} datatype="passForm">
            <label htmlFor="sucPass">Contraseña</label>
            <input autoComplete="new-password" type="password" name="password" id="sucPass" onChange={handleOnChange} />
          </div>
        </section>
        <h2>Información Sucursal</h2>
        <section className={ui.formCard}>
          <div className={ui.inputForm} datatype="doubleSpace">
            <label htmlFor="sucAlias">Alias del domicilio</label>
            <input type="text" name="alias" id="sucAlias" onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucZipCode">Código Postal</label>
            <input type="text" name="zipcode" id="sucZipCode" onChange={(e) => handleZipCode(e)} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucState">Estado</label>
            <input type="text" disabled name="state" id="sucState" defaultValue={infoPostalCode.state} onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucCol">Colonia</label>
            <input type="text" name="colony" id="sucCol" defaultValue={infoPostalCode.colony} onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucCity">Ciudad</label>
            <input type="text" name="city" id="sucCity" defaultValue={infoPostalCode.city} onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucStreet">Calle</label>
            <input type="text" name="street_name" id="sucStreet" onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucNoExt">Número Externo</label>
            <input type="text" name="ext_number" id="sucNoExt" onChange={handleOnChange} />
          </div>
          <div className={ui.inputForm}>
            <label htmlFor="sucNoInt">Número Interno</label>
            <input type="text" name="int_number" id="sucNoInt" onChange={handleOnChange} />
          </div>
          <div className={ui.textAreaForm}>
            <label htmlFor="sucReference">Referencias del lugar</label>
            <textarea name="references" id="sucReference" onChange={handleOnChange} />
          </div>
        </section>
        <div className={ui.formFooterTools}>
          <button type="button" className={ui.cancelButton} onClick={() => selectToggleModal(select, false)}>
            <span>Cancelar</span>
          </button>
          <button type="submit" className={ui.createButton} disabled={isDisabled}>
            <span>Crear</span>
          </button>
        </div>
      </form>
    </div>
  )
}

export default ModalSucursal;