import ui from "../index.module.css";
import { formatCurrency } from "../../../utils/general";

const MediumCard = ({ title, value }) => {

  return (
    <article className={ui.mediumCard}>
      <div className={ui.mCardContent}>
        <h1 className={ui.mCardTitle}>{title}</h1>
        <span className={ui.mCardValue}>{formatCurrency(value)} MXN</span>
      </div>
    </article>
  )
}


export default MediumCard;