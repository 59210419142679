import { useState } from "react";

const useAction = () => {

  const [openModal, setOpenModal] = useState(false);
  // const [openModalAdmin, setOpenModalAdmin] = useState(false);
  // const [openModalRepartidor, setOpenModalRepartidor] = useState(false);
  // const [openModalSucursal, setOpenModalSucursal] = useState(false);
  // const [openModalViewUser, setOpenModalViewUser] = useState(false);
  // const [openModalViewWarehouse, setOpenModalViewWareHouse] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [indexU, setIndexU] = useState(0);

  const selectToggleModal = (type = 0, value = false, index = null) => {
    // if (condition === null || condition === undefined) {
    setOpenModal(value);
    // if (type === 1) { setOpenModalAdmin(value); }
    // if (type === 2) { setOpenModalRepartidor(value); }
    // if (type === 3) { setOpenModalSucursal(value); }
    // if (type === 4) { setOpenModalViewUser(value); }
    // if (type === 5) { setOpenModalViewWareHouse(value); }
    // }
    // console.log({ type, value, condition })
    // if (type === 4 && condition === true) {
    //   setModalType(0);
    //   setOpenModal(value);
    // }
    // console.log({ type, value, index })
    setModalType(type);
    setIndexU(index);
  }

  const getModalStatus = (option = 0) => {
    if (option === null || option === undefined) return { t: 0, f: false, i: null }
    /*if (option === 0) */ return { t: modalType, f: openModal, i: indexU };
    /*if (option === 1)  return { t: modalType, f: openModalAdmin };*/
    /*if (option === 2)  return { t: modalType, f: openModalRepartidor };*/
    /*if (option === 3)  return { t: modalType, f: openModalSucursal };*/
    /*if (option === 4)  return { t: modalType, f: openModalViewUser };*/
    /*if (option === 5)  return { t: modalType, f: openModalViewWarehouse };*/
  }

  return {
    selectToggleModal,
    getModalStatus
  }
}

export default useAction;