const mapToArray = (Array) => {
  return Array?.map((object) => {
 
    return [
      object.user_name,
      object.user_state,
      object.role_type,
      object.balance,
      object.id_user,
     
    ]
  });
}

const mapper = (d) => {
  const headers = [
    "NOMBRE",
    "ESTADO",
    "TIPO",
    "SALDO",
    "ACCIONES"
  ];

  const balances = mapToArray(d);

  return {
    headers,
    balances
  }
}

export default mapper;