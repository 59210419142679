import React, { useContext, useEffect, useState} from "react";
import useFetch from "./hooks/useFetch";
import useAction from "../Users/hooks/useAction";
import { NotificationContext } from "../../context/NotificationContext";
import Layout from "../../components/Layout";
import BasicTable from "./BasicTable/index";
import Pagination from "./BasicTable/Pagination/index";
import Modal from "../../components/ModalWrapper";
import ModalResponse from "../../components/ModalResponse";
import ui from "./DistibutionAssignment.css";
import SearchIcon from "../../assets/icons/search-icon.svg";
import useComponent from "./hooks/useComponent";
// import imgExcel from "../../assets/icons/excel .svg";
import imgRecoleccionLores from "../../assets/icons/recoleccionLotes.svg"
// import { createBatchReport } from "../../api/createBatchReport";
import { getDealers } from "../../api/getDealers";
import { getCookie } from "../../utils/sessionCookie";
import { createDeliveryRoute } from "../../api/createDeliveryRoute";

const DistributionAssingment = () => {
  const {
    guides,
    headers,
    limit,
    offset,
    setOffset,
    currentPage,
    setCurrentPage,
    count,
    refetchData,
    searchTerm,
    setSearchTerm,

  } = useFetch(); 

  const { handleShowNotification } = useContext(NotificationContext);


  const { selectToggleModal, getModalStatus } = useAction();

  const { thereIsComponent } = useComponent({ selectToggleModal });

  const { notification, notificationType } = useContext(NotificationContext);

  const lastIndex = currentPage * offset;
  const firstIndex = lastIndex - offset;

  // const [snipper, setSnipper] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const token = getCookie("accessToken");


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedUserId(null)
  };
  // Repartidor
  const [selectedUserId, setSelectedUserId] = useState('');
  const [usersOptions, setUsersOptions] = useState([]);
  const [selectedRowsToAssigned, setSelectedRowsToAssigned] = useState([])

useEffect(() => {
  
  const fetchUsersOptions = async () => {
    try {
      const response = await getDealers();
      const { status_Code, users } = response.data;

      if (status_Code === 200) {
        const options = users.map((user) => ({
          label: user.name_complete,
          value: user.id_user,
        }));
        setUsersOptions(options);
      } else {
        console.error("Error al obtener opciones de usuarios");
      }
    } catch (error) {
      console.error("Error de red al obtener opciones de usuarios", error);
    }
  };

  fetchUsersOptions();
}, []); 


  const handleClickCheckOnRow = (id) => { 
    const isIdOnArray = selectedRowsToAssigned.indexOf(id);
    if (isIdOnArray !== -1) {
      setSelectedRowsToAssigned(prevState => prevState.filter(currentId => currentId !== id))
    }else{
      setSelectedRowsToAssigned([...selectedRowsToAssigned, id])
    }
  }
  const isButtonDisabled = selectedRowsToAssigned.length === 0;

  const isButtonDisabledModal = selectedUserId === null || selectedUserId === ''; 

  // const handleDownload = () => {
  //   setSnipper(true);
  //   createBatchReport()
  //     .then((res) => {
  //       const { status_Code, url_collect_batchs_report } = res.data;
  //       if (status_Code === 201) {
  //         const downloadLink = document.createElement("a");
  //         downloadLink.href = url_collect_batchs_report;
  //         downloadLink.download = "nombre_del_archivo.xlsx";
  //         document.body.appendChild(downloadLink);
  //         downloadLink.click();
  //         document.body.removeChild(downloadLink);
  //         setTimeout(() => {
  //           setSnipper(false);
  //         }, 5000);
  //       } else {
  //         console.error("Hubo un problema al generar el reporte.");
  //         setSnipper(false);
  //       }
  //     })
  //     .catch((error) => console.log(error));
  //     setSnipper(false);
  // };
  const handleAssignatureDelivery = () =>{
     const payload ={
      list_id_guides: selectedRowsToAssigned, 
      name_delivery_man: selectedUserId
    }
   
    createDeliveryRoute(payload, token)
    .then(res => {
      if (res.data.status_Code === 201) {
        const downloadLink = document.createElement('a');
        setTimeout(() => {
          handleShowNotification(createDeliveryRoute);
        }, 500);
        downloadLink.href = res.data.url_pdf_batch_route;
        downloadLink.target = '_blank';
        downloadLink.download = 'codigo_qr_de_ruta.pdf';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink)
        refetchData(); 
        closeModal();
        setSelectedRowsToAssigned([])
        setSelectedUserId(null)
        setSelectedRowsToAssigned([])
        
      }else{
        closeModal()
        handleShowNotification();
      }
    })
    .catch((error) => {
      closeModal()
      handleShowNotification();
    })
  }
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  
  return (
    <Layout>
      <div id="page">
        <h1>Resumén de guías para asignar a los repartidores</h1>
        <p>
          Visualizar las guías que estan por asignar a los repartidores y descarga la información
        </p>
        <section className="body">
          <div className="container">
            <div className="containerFilters">
              <div className="filterSearchBar">
                <div className="searchBar">
                  <input 
                    type="text" 
                    placeholder="Buscar usuario" 
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    // onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  <div
                    className="searchIcon"
                    style={{ backgroundImage: `url(${SearchIcon})` }}
                  ></div>
                </div>
              </div>
              <div className="containerButtons">
                <div>
                  <button
                    type="button"
                    className={`buttonAsignar ${isButtonDisabled ? 'disabled' : ''}`}
                    onClick={openModal}
                    disabled={isButtonDisabled}
                  >
                    <span>Asignar Guías</span>
                    <img src={imgRecoleccionLores} alt="loop" />
                  </button>
                </div>
                {/* <div>
                  {snipper ? ( 
                    <button   
                      type="button"
                      className="buttonExcel"
                      onClick={handleDownload}>  
                      <span>Generando Reporte</span>
                      <div className="spinner"></div>
                    </button>)
                    :(
                    <button
                      type="button"
                      className="buttonExcel"
                      onClick={handleDownload}>  
                      <span>Generar Reporte</span>
                      <img src={imgExcel} alt="loop" />
                    </button>
                    )

                  }
                </div> */}
              </div>
            </div>
            <div className={ui.containerTable}>
              <BasicTable
                information={guides}
                headers={headers}
                thereIsComponent={thereIsComponent}
                lastIndex={lastIndex}
                firstIndex={firstIndex}
                handleCheckClick={handleClickCheckOnRow}
                selectedRowsToAssigned={selectedRowsToAssigned} 
              />
              <Pagination
                count={count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limit={limit}
                offset={offset}
                setOffset={setOffset}
              />
            </div>
          </div>
          {isOpen && (
            <div className="modal-overlay"
            onClick={(e) =>{
              if (e.target.classList.contains("modal-overlay")) {
                closeModal();
              }
            }}
            >
              <div className="modalAsignature">
                <p className="titleModal">Selecciona el repartidor</p>
                <p className="textModal">Las guías serán asignados de manera automática</p>
                
                <select
                  className="inputDropdownModal"
                  id="userDropdown"
                  value={selectedUserId}
                  onChange={(e) => setSelectedUserId(e.target.value)}
                >
                  <option value=''>
                    Elige un repartidor
                  </option>
                  {usersOptions.map((user) => (
                    <option key={user.value} value={user.label}>
                      {user.label}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className={`btnModalAsignature ${isButtonDisabledModal ? 'disable' : ''}`}
                  disabled={isButtonDisabledModal}
                  onClick={handleAssignatureDelivery}
                >
                  Asignar
                </button>
          
              </div>
            </div>
          )}
        </section>
      </div>
      <Modal
        getModalStatus={getModalStatus}
        selectToggleModal={selectToggleModal}
      />
      {notification ? (
        <ModalResponse
          notificationType={notificationType}
          refetchData={refetchData}
        />
      ) : null}
    </Layout>
  );
};

export default DistributionAssingment;
