import axios from "axios";
import ui from "./index.module.css";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { getCookie } from "../../../../utils/sessionCookie";
import { NotificationContext } from "../../../../context/NotificationContext";

const ModalChangeState = ({
  selectToggleModal,
  reference,
  title = {},
  styled = {}
}) => {

  const [user, setUser] = useState({
    id_user: "",
    name: "",
    status: false
  });
  const token = getCookie("accessToken");

  const { handleShowNotification } = useContext(NotificationContext);

  useEffect(() => {

    const httpRequest = async () => {
      try {
        const {
          REACT_APP_API_URL,
        } = process.env;

        const url = REACT_APP_API_URL + "admin/get-info-users";
   
        const headers = {
          "auth_token": token,
          "Content-Type": "application/json;charset=UTF-8"
        }

        const data = { id_user: reference };

        await axios
          .post(url, data, { headers })
          .then(response => {
            const { info_user: i, status_Message: status } = response.data;
            if (status === "exist user") {
              const {
                user_name: name,
                paternal_lastname: plastname,
                maternal_lastname: mlastname,
                user_state: status
              } = i;
              setUser(() => {
                return {
                  id_user: reference,
                  name: [name, plastname, mlastname].join(" "),
                  status
                }
              })
            }
          })
      } catch (error) {
        // console.log({ error });
      }
    }

    httpRequest();

  }, [reference, token]);

  const handleChangeStatus = async () => {

    const {
      REACT_APP_API_URL: baseUrl
    } = process.env;

    const data = {
      "id_user_destiny": reference,
      "option_user_state": !user.status
    }
    const headers = {
      "auth_token": token,
      "Content-Type": "application/json;charset=UTF-8"
    }
    const response = await axios
      .post(`${baseUrl}admin/change-user-state`, data, { headers })
      .then(res => {
        // console.log({ res });
        const { status_Code: status, status_Message: message } = res.data;
        return (status === 200 && message === "state was changed");
      })
      .catch(error => {
        // console.log({ error })
        return false;
      });

    handleShowNotification(response);
    selectToggleModal(5, false);
  }


  return (
    <>
      <h1 style={{ ...styled.main, textAlign: "center" }}>
        {user.status ? title.isTrue : title.isFalse}
      </h1>
      <div className={ui.modalUserContent}>
        <h1 className={ui.contentName}>{user.name}</h1>
        <div className={ui.contentButtons}>
          <button type="button" className={ui.cancelButton} onClick={() => { selectToggleModal(5, false); }}>
            <span>Cancelar</span>
          </button>
          <button type="button" className={ui.continueButton} onClick={() => { handleChangeStatus(); }}>
            <span>Continuar</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default ModalChangeState;