import React, { useState } from "react";
import styled from "styled-components";
import Loader from "../sharedComponents/Loader";
import Dropdown from "../sharedComponents/Dropdown";

const TableContainer = styled.div`
  width: 59.4375rem;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(129, 116, 116, 0.25);
  margin: 2rem auto;
  text-align: center;
  overflow:auto;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 4.5625rem;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
  background: #fff;
  align-items: center;
  padding: 0 1rem;
  font-weight: bold;
  box-shadow: 0px 4px 12px 0px rgba(129, 116, 116, 0.25);
  text-align: center;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 4.375rem;
  background: #fff;
  align-items: center;
  padding: 0 1rem;
  box-shadow: 0px 2px 6px 0px rgba(129, 116, 116, 0.15);
  margin-bottom: 0.25rem;
  text-align: center;

  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  text-align: center;
`;

const RowsPerPageContainer = styled.div`
  heigth: 100%;
  width: 300px;
  display: flex;
`;

const RowsPerPageParagraph = styled.p`
  font-family: MontserratSemibold;
  font-size: .8rem;
  margin-right: 1rem;
`;

const PaginationButton = styled.button`
  background-color: transparent;
  color: ;
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease, font-size 0.3s ease, background-color 0.3s ease;
  margin: 0.5rem 0.5rem;
  &:hover {
    color: #b63b3b;
  }

  ${({ active }) =>
    active &&
    `
    color: #fff !important;
    background-color: #D15252;
    font-size: 1.3rem;
    font-family:MontserratBold;
    transition: background-color 0.3s ease, font-size 0.3s ease;
  `}
`;

const LoadingContainerClientTable = styled.div`
  grid-column: 1 / -1; /* Ocupa todas las columnas */
  background-color: #f0f0f0;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 6px 0px rgba(129, 116, 116, 0.15);
`;

const ClientsTable = (props) => {
  const {
    setCurrentClientInfo,
    setIsAddCreditModalOpen,
    clientInfoRows,
    isLoadingData,
  } = props;

  const handlesetCurrentClientInformation = (info) => {
    setCurrentClientInfo(info);
    setIsAddCreditModalOpen(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPageDropDownOption, setRowsPerPageDropDownOption] = useState(5);
  const rowsPerPage = rowsPerPageDropDownOption; // Número de elementos por página
  const totalPages = Math.ceil(clientInfoRows.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const sortedData = clientInfoRows.sort((a, b) => a.id_user - b.id_user);

  const currentData = sortedData.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeOptionRowPerPage = (option) => {
    setRowsPerPageDropDownOption(option);
  };

  return (
    <TableContainer>
      <TableHeader>
        <div>ID</div>
        <div>Nombre</div>
        <div>Estado</div>
        <div>Tipo</div>
        <div>Negocio</div>
        <div>Créditos</div>
        <div>saldo a Favor</div>
      </TableHeader>
      {isLoadingData ? (
        <LoadingContainerClientTable>
          <Loader />
        </LoadingContainerClientTable>
      ) : (
        <>
          {currentData &&
            currentData.length > 0 &&
            currentData.map(
              ({
                id_user,
                user_name,
                maternal_lastname,
                paternal_lastname,
                trust_business,
                user_state,
                business_name,
                credits,
                balance
              }) => {
                const fullName = `${user_name} ${paternal_lastname} ${maternal_lastname}`;
                const userType =
                  trust_business === 0 ? "No confianza" : "Confianza";
                const status = user_state === 1 ? "activo" : "inactivo";
                const clientPayloadInfo = {
                  clientId: id_user,
                  clientName: fullName,
                  clientStatus: status,
                  clientType: userType,
                  clientCompany: business_name,
                  clientCredits: credits,
                  clientBalance: balance
                };

                return (
                  <TableRow
                    key={id_user}
                    onClick={() =>
                      handlesetCurrentClientInformation(clientPayloadInfo)
                    }
                  >
                    <div>{id_user}</div>
                    <div>{fullName}</div>
                    <div>{status}</div>
                    <div>{userType}</div>
                    <div>{business_name}</div>
                    <div>{credits}</div>
                    <div>{balance}</div>
                  </TableRow>
                );
              }
            )}
        </>
      )}

      <PaginationContainer>
        <RowsPerPageContainer>
          <RowsPerPageParagraph>Renglones por pagina</RowsPerPageParagraph>
          <Dropdown
            options={["5", "10", "15", "20"]}
            value={rowsPerPageDropDownOption}
            onSelect={handleChangeOptionRowPerPage}
            defaultOption={5}
          />
        </RowsPerPageContainer>
        {Array.from({ length: totalPages }, (_, index) => (
          <PaginationButton
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            style={{
              color: currentPage === index + 1 ? "#B63B3B" : "#D15252",
            }}
            active={index + 1 === currentPage}
          >
            {index + 1}
          </PaginationButton>
        ))}
      </PaginationContainer>
    </TableContainer>
  );
};

export default ClientsTable;
