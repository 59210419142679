import ui from "./index.module.css";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { getCookie } from "../../../../utils/sessionCookie";
import { NotificationContext } from "../../../../context/NotificationContext";
import { changeStatusGuides } from "../../../../api/changesStatusGuide";

const ModalChangeStateGuides = ({
  selectToggleModal,
  reference,
}) => {
  console.log(reference)
  const [guide, setguide] = useState({
    id_guide: '',
    id_batch: '',
    tracking_id: null
  });
  useEffect(() => {
    setguide({
      id_guide: reference[0],
      id_batch: reference[1],
      tracking_id: reference[2]
    })
  
  }, [reference])
  console.log(guide)
  const token = getCookie("accessToken");
  console.log(token)
  const payload = {
      id_guide : guide.id_guide,
      id_batch : guide.id_batch
  }

  const { handleShowNotification } = useContext(NotificationContext);

  const handleChangeStatus =  () => {
    changeStatusGuides(payload, token)
    .then((res)=>{
      if (res.data.status_Code === 200 && res.data.status_Message === 'status changed') {
        console.log(res.data)
        handleShowNotification(changeStatusGuides);
      }else{
        handleShowNotification(changeStatusGuides);
      }
    })
    selectToggleModal(5, false);
  }

  return (
    <>
      <div className={ui.modalUserContent}>
        <h1 className={ui.contentName}>{guide.tracking_id}</h1>
        <div className={ui.contentButtons}>
          <button type="button" className={ui.cancelButton} onClick={() => { selectToggleModal(5, false); }}>
            <span>Cancelar</span>
          </button>
          <button type="button" className={ui.continueButton} onClick={() => { handleChangeStatus(); }}>
            <span>Continuar</span>
          </button>
        </div>
      </div> 
    </>
  )
}

export default ModalChangeStateGuides;