import React from "react";
import LoginBg from "../../../assets/images/BackgroundGirl.png";
const LoginPage = () => {
  return (
    <div className="bg-gray-100 flex justify-center items-center min-h-screen">
      {/* Versión para pantallas grandes */}
      <div className="hidden md:flex md:w-1/2">
        <div
          className="bg-cover bg-center min-h-screen min-w-full flex justify-center items-center"
          style={{ backgroundImage: `url(${LoginBg})` }}
        >
          <p className="font-bold text-white"> Hello</p>
        </div>
      </div>

      {/* Versión para pantallas medianas y pequeñas */}
      <div className="w-full md:w-1/2">
        <div className="bg-slate-600 min-h-screen min-w-full flex justify-center items-center ">
          <p className="font-bold text-white"> Hello</p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
