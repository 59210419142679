import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getCookie } from "../../../utils/sessionCookie";
import { ROUTES } from "../../../constants/routes";

const PublicRoute = ({ children }) => {
  const accessToken = getCookie("accessToken");

  if (accessToken) {
    return <Navigate to={ROUTES.HOME} />;
  }
  return children ? children : <Outlet />;
};

export default PublicRoute;
