import LoaderTable from "../../../components/animations/loaderTable";
import useFetch from "../hooks/useFetch";
import ui from "./index.module.css";
import Row from "./row";
// import useWindowSize from "../../../hooks/useWindowSize"; 

const BasicTable = ({
  information = [],
  headers = [],
  thereIsComponent = null,
  firstIndex,
  lastIndex
}) => {
  const { loader } = useFetch();

  return (
    <>
      <div id={ui.tableContainer}>
        <table id={ui.basicTable}>
          <thead>
            <tr>{headers?.map((head, index) => <th key={index}>{head}</th>)}</tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td
                  colSpan={headers.length}
                  style={{ textAlign: "center", marginTop: 40 }}
                >
                  <div className={ui.containerLoader}>
                    <LoaderTable />
                  </div>
                </td>
              </tr>
            ):(
              information?.map((row, index) => {
                return (
                  <Row
                    key={index}
                    rowIndex={index}
                    row={row}
                    headers={headers}
                    thereIsComponent={thereIsComponent}
                  />
                )
              }).slice(firstIndex, lastIndex)
            ) 
            }
          
          </tbody>
        </table>
      </div>
    </>
  )
}

export default BasicTable;