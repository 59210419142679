import React from "react";
import BodyModal from "../../../components/sharedComponents/BodyModal";
import ModalContent from "./ModalContent";
import { toast } from "react-hot-toast";

const AddCreditsModal = (props) => {
  const {
    addingCredits,
    isAddModalOpen,
    UpdateConfirmModalForm,
    closeCreditFlowModal,
    handleAddCredits,
    handleRemoveCredits,
    blockAddCreditsButton,
  } = props;

  const handleConfirmAdd = () => {
    handleAddCredits()
      .then((res) => {
        console.log(res);
        if (
          res.data.status_Code === 201 &&
          res.data.status_Message === "credits was added"
        ) {
          setTimeout(() => {
            UpdateConfirmModalForm(false);
            setTimeout(() => {
              closeCreditFlowModal();
              toast.success("Los créditos fueron agregados con éxito");
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }, 500);
          }, 1000);
        }
      })
      .catch((err) => {
        throw new Error("Something happens", err);
      });
  };

  const handleConfirmRemove = () => {
    handleRemoveCredits()
      .then((res) => {
        console.log(res);
        console.log(res);
        if (
          res.data.status_Code === 200 &&
          res.data.status_Message === "credits was reduce"
        ) {
          setTimeout(() => {
            UpdateConfirmModalForm(false);
            setTimeout(() => {
              closeCreditFlowModal();
              toast.success("Los créditos fueron removidos con éxito");
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }, 500);
          }, 1000);
        }
      })
      .catch((err) => {
        throw new Error("Something happens", err);
      });
  };

  const handleCancel = () => {
    UpdateConfirmModalForm(false);
  };

  return (
    <BodyModal
      dropShadow={true}
      open={isAddModalOpen}
      bodyHTML={
        <ModalContent
          isAdding={addingCredits}
          confirm={addingCredits ? handleConfirmAdd : handleConfirmRemove}
          cancel={handleCancel}
          disabledButton={blockAddCreditsButton}
        />
      }
    />
  );
};

export default AddCreditsModal;