import { useState } from "react";
import { createContext } from "react";

export const NotificationContext = createContext(null);

const NotificationProvider = ({ children }) => {

  const [notification, setNotification] = useState(false);
  const [notificationType, setNotificationType] = useState(false);

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationType(false);
  }

  const handleShowNotification = (e) => {
    setNotification(true);
    setNotificationType(e);
  }

  return (
    <NotificationContext.Provider
      value={{
        notification,
        notificationType,
        handleCloseNotification,
        handleShowNotification
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider;