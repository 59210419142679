import SuccessIcon from "../../../assets/icons/check-circle.svg";
import CancelIcon from "../../../assets/icons/cancel-circle.svg";
import useWindowSize from "../../../hooks/useWindowSize";
import ui from "./index.module.css";

const Row = ({ rowIndex, row, headers, thereIsComponent = null }) => {
  const { width } = useWindowSize();

  // console.log(row)

  return width <= 480 ? (
    <tr className={ui.row}>
      <tr className={ui.rowTop}>
        <td>
          <span>{headers[0]}</span>
          <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            {row[0]}
          </span>
        </td>
        
        <td></td>
        <td></td>
      </tr>
      <tr className={ui.rowBottom}>
        <td>
            <span>{headers[2]}</span>
            <span>{row[2]}</span>
        </td>
        <td>
            <span>{headers[3]}</span>
            <span>{row[3]}</span>
        </td>
        <td className={ui.cellStatus}>
          <span>{headers[1]}</span>
          {row[1] ? (
            <span datatype="success">Activo</span>
          ) : (
            <span datatype="cancel">Inactivo</span>
          )}
        </td>
        {thereIsComponent ? thereIsComponent(row[0]) : null}
    
         
 
      </tr>
    </tr>
  ) : (
    <tr >
      {row?.map((cell, index) => {
       
        if (index === 1) {
          return (
            <td key={index} style={{textAlign:'center'}}>
              {cell ? (
                <img alt="success" src={SuccessIcon} />
              ) : (
                <img alt="cancel" src={CancelIcon} />
              )}
            </td>
          );
        }
        if (index === 4) {
          return null
        }
        return <td key={index}>{cell}</td>;
      })}
      {thereIsComponent ? thereIsComponent([row[0], row[4]]) : null}
    </tr>
  );
};

export default Row;
