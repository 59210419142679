import React, { useState } from "react";
import { styled } from "styled-components";
import searchInputIcon from "../../assets/icons/SearchInputIcon.svg";
const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 36rem;
  height: 3rem;
  border-radius: 1.875rem;
  background: #fff;
  padding: 0.5rem;
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  outline: none;
  padding-left: 0.5rem;
  font-size: 1rem;
  color: #000;
  padding: 2rem;
  font-family: MontserratMedium;
  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const SearchIcon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  cursor: pointer;
`;

const SearchInput = (props) => {
  const { clientInfoRows, onFilterChange } = props;
  const [searchValue, setSearchValue] = useState("");

  const handleSearchInputChange = (value) => {
    setSearchValue(value);

    // Realizar el filtrado en base al valor del input
    const filteredRows = clientInfoRows.filter((row) =>
      row.user_name.toLowerCase().includes(value.toLowerCase())
    );

    onFilterChange(filteredRows); // Pasar el resultado filtrado al padre
  };

  return (
    <SearchInputContainer>
      <Input
        type="text"
        placeholder="Buscar cliente por nombre o ID"
        value={searchValue}
        onChange={(e) => handleSearchInputChange(e.target.value)}
      />
      <SearchIcon src={searchInputIcon} alt="Search" />
    </SearchInputContainer>
  );
};

export default SearchInput;
