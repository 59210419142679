import { useContext, useState } from "react";
import ui from "./index.module.css";
import { useEffect } from "react";
import axios from "axios";
import { getCookie } from "../../../../utils/sessionCookie";
import { NotificationContext } from "../../../../context/NotificationContext";
import { encryptPassword } from "../../../../utils/general";

const ModalContentRepartidor = ({ selectToggleModal, type }) => {

  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [formData, setFormData] = useState({
    user_name: "",
    paternal_lastname: "",
    maternal_lastname: "",
    telephone_number: "",
    email: "",
    password: "",
  });

  const { handleShowNotification } = useContext(NotificationContext);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const url = process.env.REACT_APP_API_URL + "admin/add-delivery-man-admin";
    const {
      user_name,
      paternal_lastname,
      maternal_lastname,
      telephone_number,
      email,
      password,
    } = formData;
    const encryptedPassword = encryptPassword(password);

    const data = {
      role_type: "delivery",
      user_info: {
        user_name,
        paternal_lastname,
        maternal_lastname,
        telephone_number,
        email,
        password: encryptedPassword,
      },
    };

   
    const token = getCookie("accessToken");
    const headers = {
      "auth_token": token,
      "Content-Type": "application/json;charset=UTF-8"
    }
    const response = await axios.post(url, data, { headers })
    .then(res => {
      return { response: true };
    })
    .catch(error => {
      return { response: false };
    });

    setLoading(false);
    selectToggleModal(type, false);    
    handleShowNotification(response);
    return;
  }

  const handleOnChange = (e) => {

    const name = e.target.name;
    const value = e.target.value;

    if (value === "" && isDisabled === false) {
      setIsDisabled(true);
      return;
    }

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value
      }
    });
  }

  useEffect(() => {

    const handleEffect = () => {

      const dataList = Object.entries(formData);
      const list = dataList?.map(item => item[1] === "");
      const isEmpty = list.some(item => item === true);
      if (isEmpty === true) return;
      setIsDisabled(false);
      return;
    }

    handleEffect();

  }, [formData])

  return (
    <div className={ui.formContainer}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className={ui.inputForm}>
          <label htmlFor="repName">Nombre</label>
          <input type="text" name="user_name" id="repName" onChange={handleOnChange} />
        </div>
        <div className={ui.inputForm}>
          <label htmlFor="repPatLastName">Apellido Paterno</label>
          <input type="text" name="paternal_lastname" id="repPatLastName" onChange={handleOnChange} />
        </div>
        <div className={ui.inputForm}>
          <label htmlFor="repMatLastName">Apellido Materno</label>
          <input type="text" name="maternal_lastname" id="repMatLastName" onChange={handleOnChange} />
        </div>
        <div className={ui.inputForm}>
          <label htmlFor="repPhone">Teléfono</label>
          <input type="text" name="telephone_number" id="repPhone" onChange={handleOnChange} />
        </div>
        <div className={ui.inputForm}>
          <label htmlFor="repEmail">Correo electrónico</label>
          <input autoComplete="off" type="email" name="email" id="repEmail" onChange={handleOnChange} />
        </div>
        <div className={ui.inputForm}>
          <label htmlFor="repPass">Contraseña</label>
          <input autoComplete="new-password" type="password" name="password" id="repPass" onChange={handleOnChange} />
        </div>
        <div className={ui.formFooterTools}>
          <button type="button" className={ui.cancelButton} onClick={() => selectToggleModal(type, false)}>
            <span>Cancelar</span>
          </button>
          <button type="submit" className={ui.createButton} disabled={isDisabled}>
            <span>Crear</span>
          </button>
        </div>
      </form>
    </div>
  )
}


export default ModalContentRepartidor;