import   "../StatusPackage.css";

const useComponent = ({ selectToggleModal }) => {

  const thereIsComponent = (row) => {
  // console.log(row)
    return (
      <td>
        <div className='actionTools'>
          <button type="button" className='toolButton' onClick={() => {
            selectToggleModal(6, true, row )
          }} >
            Entregar
          </button>
        </div>
      </td>
    )
  }

  return {
    thereIsComponent
  }
}

export default useComponent;