import useWindowSize from "../../../hooks/useWindowSize";
import ui from "./index.module.css";

const Row = ({row, headers, thereIsComponent = null}) => {
  const { width } = useWindowSize();



  return width <= 480 ? (
    <tr className={ui.row}>
      <tr className={ui.rowTop}>
        <td>
          <span>{headers[0]}</span>
          <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            {row[0]}
          </span>
        </td>
        <td>
          <span>{headers[1]}</span>
          <span>{row[1]}</span>
        </td>
        <td>
          <span>{headers[2]}</span>
          <span>{row[2]}</span>
        </td>
 
      </tr>
      <tr className={ui.rowBottom}>
        <td>
          <span>{headers[3]}</span>
          <span>{row[3]}</span>
        </td>
        <td>
          <span>{headers[4]}</span>
          <span>{row[4]}</span>
        </td>
        {thereIsComponent ? thereIsComponent(row[0]) : null}

      </tr>
    </tr>
  ) : (
    <tr>
      {row?.map((cell, index) => {
        if (index === 5 || index === 6) {
          return null
        }
        return <td key={index}>{cell}</td>;
      })}
      {thereIsComponent ? thereIsComponent([row[5], row[6], row[0]]) : null}
    </tr>
  );
};

export default Row;
